import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, Prompt, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Beforeunload } from "react-beforeunload";
import { Link } from "react-scroll";
import {
  Formik,
  Form,
  useField,
  FormikConsumer,
  useFormikContext,
} from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { parseISO } from "date-fns";
import ContextMenus from "./ContextMenus";
import CNEATContextMenus from "./CNEATContextMenus";

import { useUser } from "../UserContext";
import { getCookieWithSuffix } from "../services/auth.service";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useBreakpointValue,
  HStack,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  updateSeries,
  getPreviousStudies,
  getAllReportTemplates,
  createReferringPhysician,
  getAllReferringPhysicians,
  getAllNormalValueMeasurements,
  getAllMacros,
  sendShareEmail,
} from "../services/form-api-service";

import { StorageManager } from "@aws-amplify/ui-react-storage";
import "@aws-amplify/ui-react/styles.css";

import S3AmplifyFileList from "./S3AmplifyFileList.jsx";

import { PrimaryButton, SecondaryButton } from "./Buttons";
import ScrollTopButton from "./ScrollTopButton";

import useToggle from "../hooks/useToggle";
import {
  calculateAge,
  customDateFormat,
  lastCommaFirst,
  hasMatchingWord,
} from "../utils/util";

import imgBullseye from "../images/bullseye.png";

import { useContextMenu } from "react-contexify";

export const PromptIfDirty = () => {
  const { dirty } = useFormikContext();

  if (dirty) {
    return (
      <>
        {/* This component prevents a user from closing the browser tab with unsaved data */}
        <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
        {/* This component prevents a user from navigating away from the current series with unsaved data in the form*/}
        <FormikConsumer>
          {(formik) => (
            <Prompt
              when={formik?.dirty}
              message="There are unsaved changes. Click Cancel and then save changes. Or you'll lose your data!"
            />
          )}
        </FormikConsumer>
      </>
    );
  }
  return null;
};

const FloatingButtons = ({
  patient,
  macros,
  setStudyStatus,
  setAccessType,
  user,
  isTransparent,
  children,
}) => {
  const { dirty, submitForm } = useFormikContext();
  const history = useHistory();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    },
    { ssr: false }
  );

  const handleClick = (auditAccessType) => {
    if (auditAccessType === "sign") {
      setStudyStatus("Signed");
    } else if (auditAccessType === "archive") {
      setStudyStatus("Archived");
    } else if (auditAccessType === "amend") {
      setStudyStatus("Amended");
    } else if (auditAccessType === "update") {
      user["custom:role"] === "learner"
        ? setStudyStatus("Test In Progress")
        : setStudyStatus("In progress");
    }

    setAccessType(auditAccessType);
    submitForm();

    setTimeout(() => {
      setAccessType("");
      if (auditAccessType === "archive") {
        history.push("/");
      }
    }, 2500);
  };

  const handleClickViewPDF = () => {
    const baseURL = window.location.origin;

    setAccessType("view");
    setTimeout(() => {
      setAccessType("");
      window.open(
        `${baseURL}/report/${patient.SeriesInstanceUID}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      );
    }, 2500);
    // }
  };

  return (
    <Stack
      p="0.5rem"
      spacing={2}
      align="flex-start"
      direction="column"
      borderRadius="10px"
      //      boxShadow="20px 20px 60px #bebebe, -20px -20px 60px #ffffff"
      style={{
        position: "fixed",
        top: "15vh",
        bottom: "37vh",
        right: "1rem",
        zIndex: 2,
        backgroundColor: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
      }}
    >
      <Link to="testConclusion" smooth={true} offset={-20} duration={500}>
        <Button
          p={{ base: "0.5rem", sm: "1rem" }}
          fontSize={{ base: "0.75rem", sm: "1rem" }}
          //          colorScheme="blue"
          style={{
            backgroundColor: isTransparent
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(49, 130, 206)",
            color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
          }}
        >
          Conclusion
        </Button>
      </Link>
      <Link to="lvSizeAndFunction" smooth={true} offset={-20} duration={500}>
        <Button
          p={{ base: "0.5rem", sm: "1rem" }}
          fontSize={{ base: "0.75rem", sm: "1rem" }}
          //          colorScheme="blue"
          style={{
            backgroundColor: isTransparent
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(49, 130, 206)",
            color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
          }}
        >
          Ventricular
        </Button>
      </Link>
      <Link to="aortaAndAv" smooth={true} offset={-20} duration={500}>
        <Button
          p={{ base: "0.5rem", sm: "1rem" }}
          fontSize={{ base: "0.75rem", sm: "1rem" }}
          //          colorScheme="blue"
          style={{
            backgroundColor: isTransparent
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(49, 130, 206)",
            color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
          }}
        >
          Valvular
        </Button>
      </Link>
      {user["custom:clinicName"]?.includes("PACE") ? (
        <Link to="mvAndTv" smooth={true} offset={-20} duration={500}>
          <Button
            p={{ base: "0.5rem", sm: "1rem" }}
            fontSize={{ base: "0.75rem", sm: "1rem" }}
            //          colorScheme="blue"
            style={{
              backgroundColor: isTransparent
                ? "rgba(0, 0, 0, 0.05)"
                : "rgba(49, 130, 206)",
              color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
            }}
          >
            Mitral
          </Button>
        </Link>
      ) : (
        <Link to="laAndRa" smooth={true} offset={-20} duration={500}>
          <Button
            p={{ base: "0.5rem", sm: "1rem" }}
            fontSize={{ base: "0.75rem", sm: "1rem" }}
            //          colorScheme="blue"
            style={{
              backgroundColor: isTransparent
                ? "rgba(0, 0, 0, 0.05)"
                : "rgba(49, 130, 206)",
              color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
            }}
          >
            Atria
          </Button>
        </Link>
      )}
      {dirty && (
        <Button
          p={{ base: "0.5rem", sm: "1rem" }}
          fontSize={{ base: "0.75rem", sm: "1rem" }}
          //          colorScheme="red"
          onClick={() => {
            patient.studyStatus === "Signed"
              ? !user["custom:clinicName"]?.includes("MK")
                ? handleClick("amend")
                : handleClick("update")
              : handleClick("update");
          }}
          style={{
            backgroundColor: isTransparent
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(229, 62, 62)",
            color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
          }}
        >
          SAVE
        </Button>
      )}
      {(hasMatchingWord(user["custom:clinicName"], "MK") ||
        hasMatchingWord(user["custom:clinicName"], "prime") ||
        user?.["custom:role"] === "cardiologist" ||
        user?.["custom:role"] === "educator" ||
        user?.["custom:role"] !== "external" ||
        user?.["custom:role"] !== "patient") && (
        <Button
          p={{ base: "0.5rem", sm: "1rem" }}
          fontSize={{ base: "0.75rem", sm: "1rem" }}
          //          colorScheme="red"
          type="button"
          onClick={() => handleClick("sign")}
          style={{
            backgroundColor: isTransparent
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(229, 62, 62)",
            color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
          }}
        >
          SIGN
        </Button>
      )}
      <Button
        p={{ base: "0.5rem", sm: "1rem" }}
        fontSize={{ base: "0.75rem", sm: "1rem" }}
        type="button"
        //        colorScheme="blue"
        onClick={handleClickViewPDF}
        style={{
          backgroundColor: isTransparent
            ? "rgba(0, 0, 0, 0.05)"
            : "rgba(49, 130, 206)",
          color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
        }}
      >
        View Report
      </Button>
      {children}
      <ModalWithGrid
        buttonText="Macros list"
        headingText="Available macros"
        isTransparent={isTransparent}
        style={{
          backgroundColor: isTransparent
            ? "rgba(0, 0, 0, 0.05)"
            : "rgba(49, 130, 206)",
          color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
        }}
      >
        {macros.map((macro) => (
          <DisplayMacro macro={macro} key={macro.macroId} />
        ))}
      </ModalWithGrid>
    </Stack>
  );
};

const DemographicsContainer = ({ children }) => {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    },
    { ssr: false }
  );

  return (
    <Grid
      templateColumns={isMobile ? "28vw" : "28vw 28vw 28vw"}
      gap={isMobile ? "2vh" : "2vh 4vw"}
      padding={isMobile ? "0 1vw 2vw" : "0 1vw 2vw 2vw"}
    >
      {children}
    </Grid>
  );
};

const MeasurementsContainer = ({ children }) => {
  return (
    <Grid
      templateColumns="repeat(5, 1fr)"
      justifyItems="start"
      alignItems="end"
      autoRows="max-content"
    >
      {children}
    </Grid>
  );
};

const SecondaryMeasurementsContainer = (props) => {
  const [showSecondaryMeasurements, setShowSecondaryMeasurements] =
    useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setShowSecondaryMeasurements(!showSecondaryMeasurements);
  };

  return (
    <div>
      <div style={{ gridColumn: "1 / 6", marginTop: "1rem" }}>
        <SecondaryButton onClick={handleClick}>
          {showSecondaryMeasurements ? "HIDE" : "SHOW"} MORE MEASUREMENTS
        </SecondaryButton>
        <p
          style={{
            fontWeight: 300,
            margin: "1rem 0",
            borderBottom: "1px solid black",
            display: showSecondaryMeasurements ? "block" : "none",
          }}
        >
          More measurements
        </p>
      </div>
      {showSecondaryMeasurements && (
        <MeasurementsContainer>{props.children}</MeasurementsContainer>
      )}
    </div>
  );
};

const addAuditData = async (user, patient, auditAccessType) => {
  const clinicData = {
    clinicName: user["custom:clinicName"],
    street: user["custom:clinicStreet"],
    city: user["custom:clinicCity"],
    province: user["custom:clinicProvince"],
    country: user["custom:clinicCountry"],
    postalCode: user["custom:clinicPostalCode"],
    phoneNumber: user["custom:clinicPhoneNumber"],
    faxNumber: user["custom:clinicFaxNumber"],
    logo: user["custom:clinicLogo"],
  };

  const auditData = {
    accessType: auditAccessType,
    user: user.email,
    role: user["custom:role"],
    signatureName: user["custom:signatureName"],
    time: new Date(),
    clinic: clinicData,
  };

  // if the audit array exists, add the new auditData to it,
  // or else create an array with the new auditData
  const audit = patient.audit ? [...patient.audit, auditData] : [auditData];

  const bodyData = {
    ...patient,
    audit: audit,
    lastViewedByUser: {
      email: user.email,
      role: user["custom:role"],
      time: new Date(),
    },
  };

  const updatedData = await updateSeries(bodyData, patient.SeriesInstanceUID);
  return updatedData;
};

const StatusReportForm = ({ patient }) => {
  const user = useUser();
  const userattr = user?.attributes;
  const [macros, setMacros] = useState([]);
  const [reportTemplates, setReportTemplates] = useState([]);
  const [patientData, setPatientData] = useState({ ...patient });
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [studyStatus, setStudyStatus] = useState(
    patientData?.studyStatus || patient?.studyStatus
  );
  const [isContrastAgentUsedFlag, setIsContrastAgentUsed] = useToggle(
    patient?.isContrastAgentUsed
  );
  const [accessType, setAccessType] = useState("");
  const [patientDOB, setPatientDOB] = useState(
    patientData?.PatientBirthDate ? parseISO(patientData?.PatientBirthDate) : ""
  );
  const [newSignedDate, setNewSignedDate] = useState(new Date());
  const [referringPhysicians, setReferringPhysicians] = useState([]);
  const [previousStudies, setPreviousStudies] = useState([]);
  const [isSignedDateSelected, setIsSignedDateSelected] = useState(false);
  const [isZScoreTableDisplayed, setIsZScoreTableDisplayed] = useToggle(
    patientData?.isZScoreTableDisplayed || patient?.isZScoreTableDisplayed
  );
  const [zScoreData, setZScoreData] = useState(
    patientData?.zScore || patient?.zScore
  );
  const [shareResult, setShareResult] = useState();
  const [shareEmail, setShareEmail] = useState();
  const [shareType, setShareType] = useState();

  const [isHovered, setIsHovered] = useState(false);

  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559

  const [numFilesUploaded, _setNumFilesUploaded] = useState(0);

  const numFilesUploadedRef = useRef(numFilesUploaded);

  // create a custom function in place of the original setNumFilesUploaded
  const setNumFilesUploaded = (data) => {
    numFilesUploadedRef.current = data; // updates the ref
    _setNumFilesUploaded(data);
  };

  const isFormDisabled =
    (!hasMatchingWord(userattr["custom:clinicName"], "MK") &&
      !hasMatchingWord(userattr["custom:clinicName"], "prime") &&
      !hasMatchingWord(userattr["custom:clinicName"], "Westville") &&
      !hasMatchingWord(userattr["custom:clinicName"], "pace") &&
      userattr["custom:role"] !== "educator" &&
      studyStatus === "Signed") ||
    userattr["custom:role"] === "external" ||
    userattr["custom:role"] === "patient";
  const { show } = useContextMenu();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    },
    { ssr: false }
  );

  function displayMenu(e, menuid) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      id: menuid,
      event: e,
      props: {},
    });
  }

  const [isGoldReportFlag, setIsGoldReport] = useState(patient.isGoldReport);

  const [isBullsEyeTableDisplayed, setIsBullsEyeTableDisplayed] = useToggle(
    initialBullsEye()
  );
  const [bullsEyeData, setBullsEyeData] = useState(
    patientData.bullsEye || patient.bullsEye
  );

  function initialBullsEye() {
    if (
      patientData.isBullsEyeTableDisplayed ||
      patient.isBullsEyeTableDisplayed
    )
      return true;
    else {
      const protocolName = patientData.ProtocolName?.toLowerCase();
      const isStressEcho = protocolName?.includes("stress")
        ? true
        : protocolName?.includes("dobutamine")
        ? true
        : false;
      return isStressEcho;
    }
  }

  useEffect(() => {
    const setAuditData = async () => {
      // if (patientData.PatientBirthDate && patientData.PatientSex) {
      const updatedPatientData = await addAuditData(userattr, patient, "view");

      if (updatedPatientData) {
        setPatientData(updatedPatientData);

        // to make sure that signed date does not get overwritten when not selected
        if (updatedPatientData.newSignedDate) {
          setNewSignedDate(new Date(updatedPatientData.newSignedDate));
          setIsSignedDateSelected(true);
        }

        // check for previous studies
        const previousStudiesData = await getPreviousStudies(
          updatedPatientData.PatientID,
          updatedPatientData.SeriesDate
        );

        if (previousStudiesData.length > 0) {
          setPreviousStudies(previousStudiesData);
        }
      }
      // }
    };

    // https://stackoverflow.com/questions/42261524/how-to-window-scrollto-with-a-smooth-effect
    window.scrollTo({ top: 0, behavior: "smooth" });
    setAuditData();
  }, [patient]);

  useEffect(() => {
    const getData = async () => {
      const referringPhysiciansData = await getAllReferringPhysicians();
      setReferringPhysicians(referringPhysiciansData);

      const reportTemplatesData = await getAllReportTemplates();
      setReportTemplates(reportTemplatesData);

      const macrosData = await getAllMacros();
      setMacros(macrosData);
    };

    getData();
  }, []);

  const initialValues = {
    // text boxes and input fields
    PatientName: patientData.PatientName,
    PatientSex: patientData.PatientSex,
    PatientAge: patientData.PatientAge,
    ReferringPhysicianName: patientData.ReferringPhysicianName,
    ReferringPhysicianId: patientData.ReferringPhysicianId,
    newReferringPhysician: patientData.newReferringPhysician,
    PhysiciansOfRecord: patientData.PhysiciansOfRecord,
    OperatorsName: patientData.OperatorsName,
    Comments: patientData.Comments,
    Pathology: patientData.Pathology,
    DFA: patientData.DFA,
    testConclusion: patientData.testConclusion,
    comparisonPriors: patientData.comparisonPriors,
    comparisonPriorsOther: patientData.comparisonPriorsOther,
    reasonForTest: patientData.reasonForTest,
    reasonForTestOther: patientData.reasonForTestOther,
    studyQuality: patientData.studyQuality,
    tdsReason: patientData.tdsReason,
    otherTdsReason: patientData.otherTdsReason,
    lvSizeAndFunction: patientData.lvSizeAndFunction,
    rvPaPv: patientData.rvPaPv,
    laAndRa: patientData.laAndRa,
    aortaAndAv: patientData.aortaAndAv,
    mvAndTv: patientData.mvAndTv,
    vessels: patientData.vessels,
    otherNotes: patientData.otherNotes,
    isContrastAgentUsed: patientData.isContrastAgentUsed,
    contrastAgentNotes: patientData.contrastAgentNotes,
    BPs: patientData.BPs,
    BPd: patientData.BPd,
    PatSize: patientData.PatSize,
    PatWeight: patientData.PatWeight,
    BSA: patientData.BSA,
    Rhythm: patientData.Rhythm,
    otherRhythm: patientData.otherRhythm,
    HR: patientData.HR,

    // common stress echo fields
    pharmacologicalNotes: patientData.pharmacologicalNotes,
    stressTestProtocol: patientData.stressTestProtocol,
    reasonForStopping: patientData.reasonForStopping,
    functionalCapacity: patientData.functionalCapacity,
    hemodynamicExerciseResponse: patientData.hemodynamicExerciseResponse,

    // Bullseye stress echo fields
    basalAnterior: patientData.basalAnterior,
    basalAnteroseptal: patientData.basalAnteroseptal,
    basalInferoseptal: patientData.basalInferoseptal,
    basalInferior: patientData.basalInferior,
    basalInferolateral: patientData.basalInferolateral,
    basalAnterolateral: patientData.basalAnterolateral,
    midAnterior: patientData.midAnterior,
    midAnteroseptal: patientData.midAnteroseptal,
    midInferoseptal: patientData.midInferoseptal,
    midInferior: patientData.midInferior,
    midInferolateral: patientData.midInferolateral,
    midAnterolateral: patientData.midAnterolateral,
    apicalAnterior: patientData.apicalAnterior,
    apicalSeptal: patientData.apicalSeptal,
    apicalInferior: patientData.apicalInferior,
    apicalLateral: patientData.apicalLateral,
    apex: patientData.apex,
    wmsi: patientData.wmsi,

    // Bullseye post exercise stress echo fields
    postbasalAnterior: patientData.postbasalAnterior,
    postbasalAnteroseptal: patientData.postbasalAnteroseptal,
    postbasalInferoseptal: patientData.postbasalInferoseptal,
    postbasalInferior: patientData.postbasalInferior,
    postbasalInferolateral: patientData.postbasalInferolateral,
    postbasalAnterolateral: patientData.postbasalAnterolateral,
    postmidAnterior: patientData.postmidAnterior,
    postmidAnteroseptal: patientData.postmidAnteroseptal,
    postmidInferoseptal: patientData.postmidInferoseptal,
    postmidInferior: patientData.postmidInferior,
    postmidInferolateral: patientData.postmidInferolateral,
    postmidAnterolateral: patientData.postmidAnterolateral,
    postapicalAnterior: patientData.postapicalAnterior,
    postapicalSeptal: patientData.postapicalSeptal,
    postapicalInferior: patientData.postapicalInferior,
    postapicalLateral: patientData.postapicalLateral,
    postapex: patientData.postapex,
    postwmsi: patientData.postwmsi,

    // Prime specific fields
    clinicLocation: patientData.clinicLocation
      ? patientData.clinicLocation
      : hasMatchingWord(userattr["custom:clinicName"], "prime")
      ? "prime"
      : "",

    // Physiological stress echo fields
    baselineECG: patientData.baselineECG,
    peakExerciseECG: patientData.peakExerciseECG,
    recoveryECG: patientData.recoveryECG,
    restingStressEcho: patientData.restingStressEcho,
    peakExerciseStressEcho: patientData.peakExerciseStressEcho,
    recoveryStressEcho: patientData.recoveryStressEcho,

    // Dobutamine (Pharmacological) stress echo fields
    restingECGEcho: patientData.restingECGEcho,
    lowDoseDobutamineECGEcho: patientData.lowDoseDobutamineECGEcho,
    peakDoseDobutamineECGEcho: patientData.peakDoseDobutamineECGEcho,
    recoveryECGEcho: patientData.recoveryECGEcho,

    // lvSizeAndFunction measurements
    LVIDd: patientData.LVIDd,
    LVIDs: patientData.LVIDs,
    GLS: patientData.GLS,
    IVSd: patientData.IVSd,
    PWd: patientData.PWd,
    StrainA4: patientData.StrainA4,
    EFSimpsons: patientData.EFSimpsons,
    StrainA3: patientData.StrainA3,
    LVOTDIAM: patientData.LVOTDIAM,
    LVEDV: patientData.LVEDV,
    LVEDVx: patientData.LVEDVx,
    COx: patientData.COx,
    LVESV: patientData.LVESV,
    CO: patientData.CO,
    LVESVx: patientData.LVESVx,
    SVx: patientData.SVx,
    StrainA2: patientData.StrainA2,
    EFTeich: patientData.EFTeich,
    LVMass: patientData.LVMass,
    LVMassIndex: patientData.LVMassIndex,
    SV: patientData.SV,
    RWT: patientData.RWT,
    SVTeich: patientData.SVTeich,
    SVTeichx: patientData.SVTeichx,
    COTeich: patientData.COTeich,
    COTeichx: patientData.COTeichx,
    EFA4: patientData.EFA4,
    EFA2: patientData.EFA2,
    FS: patientData.FS,
    LVEDVA4: patientData.LVEDVA4,
    LVEDVA4x: patientData.LVEDVA4x,
    LVESVA4: patientData.LVESVA4,
    COA4: patientData.COA4,
    LVESVA4x: patientData.LVESVA4x,
    COA4x: patientData.COA4x,
    EDVTeich: patientData.EDVTeich,
    SVA4: patientData.SVA4,
    SVA4x: patientData.SVA4x,
    LVLsA4: patientData.LVLsA4,
    LVEDAA2: patientData.LVEDAA2,
    LVESAA2: patientData.LVESAA2,
    LVLdA4: patientData.LVLdA4,
    SVA2: patientData.SVA2,
    LVEDAA4: patientData.LVEDAA4,
    LVESAA4: patientData.LVESAA4,
    SVA2x: patientData.SVA2x,
    LVLsA2: patientData.LVLsA2,
    LVESVA2: patientData.LVESVA2,
    COA2: patientData.COA2,
    LVESVA2x: patientData.LVESVA2x,
    COA2x: patientData.COA2x,
    LVLdA2: patientData.LVLdA2,
    LVEDVA2: patientData.LVEDVA2,
    LVEDVA2x: patientData.LVEDVA2x,
    ESVTeich: patientData.ESVTeich,

    // rvPaPv measurements
    RVIDd: patientData.RVIDd,
    TAPSE: patientData.TAPSE,
    S: patientData.S,
    TRPG: patientData.TRPG,
    RASP: patientData.RASP,
    RVSP: patientData.RVSP,
    RVB: patientData.RVB,
    RVM: patientData.RVM,
    RVL: patientData.RVL,
    PVVel: patientData.PVVel,
    PVPG: patientData.PVPG,
    PVVTI: patientData.PVVTI,
    QPQS: patientData.QPQS,
    AVSVx: patientData.AVSVx,
    AVCOx: patientData.AVCOx,
    RVOT: patientData.RVOT,

    // laAndRa measurements
    LAd: patientData.LAd,
    LALsA4: patientData.LALsA4,
    LAAsA4: patientData.LAAsA4,
    LALsA2: patientData.LALsA2,
    LAAsA2: patientData.LAAsA2,
    LAVA4: patientData.LAVA4,
    LAESV: patientData.LAESV,
    LAV: patientData.LAV,
    RAa: patientData.RAa,
    RAV: patientData.RAV,
    s: patientData.s,
    d: patientData.d,
    a: patientData.a,
    adur: patientData.adur,
    sdratio: patientData.sdratio,
    LAVI: patientData.LAVI,
    RAVI: patientData.RAVI,
    LAVA2: patientData.LAVA2,
    LAVIA4: patientData.LAVIA4,
    LAVIA2: patientData.LAVIA2,

    // aortaAndAv measurements
    AO: patientData.AO,
    AscAo: patientData.AscAo,
    LAAO: patientData.LAAO,
    AVVmax: patientData.AVVmax,
    AVPG: patientData.AVPG,
    AVMG: patientData.AVMG,
    AVAVmax: patientData.AVAVmax,
    AVAI: patientData.AVAI,
    AVAVTI: patientData.AVAVTI,
    AVVTI: patientData.AVVTI,
    AVAIVTI: patientData.AVAIVTI,
    LVOTVmax: patientData.LVOTVmax,
    DI: patientData.DI,
    ARPHT: patientData.ARPHT,
    LVOTmaxPG: patientData.LVOTmaxPG,
    LVOTVTI: patientData.LVOTVTI,
    AVSV: patientData.AVSV,
    AVCO: patientData.AVCO,

    // mvAndTv measurements
    TRVel: patientData.TRVel,
    E: patientData.E,
    A: patientData.A,
    DecT: patientData.DecT,
    MVEA: patientData.MVEA,
    esep: patientData.esep,
    Eesep: patientData.Eesep,
    elat: patientData.elat,
    Eelat: patientData.Eelat,
    IVRT: patientData.IVRT,
    Ee: patientData.Ee,
    MVPHT: patientData.MVPHT,
    MVTRCE: patientData.MVTRCE,
    MVMRERO: patientData.MVMRERO,
    IVC: patientData.IVC,
    TRMV: patientData.TRMV,
    TRMG: patientData.TRMG,
    PAP: patientData.PAP,

    // Pediatric measurements
    pMPA: patientData.pMPA,
    pPV: patientData.pPV,
    pMV: patientData.pMV,
    pTV: patientData.pTV,
    pRPA: patientData.pRPA,
    pLPA: patientData.pLPA,
    pAS: patientData.pAS,
    pSJ: patientData.pSJ,
    pTRAR: patientData.pTRAR,
    pIST: patientData.pIST,
    pDscAo: patientData.pDscAo,

    // Augmented Learning
    isGoldReport: patientData.isGoldReport,
    LearnerID: patientData.LearnerID,

    // This has to be removed once the form is filled with all the API properties
    dummyInitialValue: "",
  };

  const protocolName = patientData.ProtocolName?.toLowerCase();

  if (protocolName?.includes("dobutamine")) {
    initialValues.stressEchoType = "pharmacological";
  } else if (protocolName?.includes("stress")) {
    initialValues.stressEchoType = "physiological";
  } else {
    initialValues.stressEchoType = patientData.stressEchoType;
  }

  const validationSchema = Yup.object({
    // temporarily disabled
    // testConclusion: Yup.string().required("Required"),
    // reasonForTest: Yup.string().required("Required"),
    // studyQuality: Yup.string().required("Required"),
    // lvSizeAndFunction: Yup.string().required("Required"),
    // rvPaPv: Yup.string().required("Required"),
    // laAndRa: Yup.string().required("Required"),
    // aortaAndAv: Yup.string().required("Required"),
    // mvAndTv: Yup.string().required("Required"),
  });

  // This is just a sample. Get list of mandatoryValues from the Report measurements document
  const mandatoryValues = [
    //   "lvMassIdx",
  ];

  function changeInitialValues() {
    for (let index = 0; index < mandatoryValues.length; index++) {
      if (!initialValues[mandatoryValues[index]]) {
        initialValues[mandatoryValues[index]] = "N/A";
      }
    }

    // Change all null initialValues to empty string to prevent controlled input to uncontrolled input error.
    Object.keys(initialValues).forEach((key) => {
      if (!initialValues[key]) {
        initialValues[key] = "";
      }
    });
  }
  changeInitialValues();

  function findFormChanges(values) {
    const changes = {};
    Object.keys(values).forEach((key) => {
      if (values[key] && values[key] !== patientData[key]) {
        changes[key] = {
          oldValue: patientData[key],
          newValue: values[key],
        };
      }
    });
    return changes;
  }

  const StatusReportLabel = ({ htmlFor, label, style }) => {
    return (
      <FormLabel
        fontSize={{ base: "0.75rem", sm: "18px" }}
        width="20vw"
        //      fontSize="18px"
        fontFamily="Jost"
        lineHeight="29px"
        htmlFor={htmlFor}
        style={{ ...style }}
      >
        {label}
      </FormLabel>
    );
  };

  // Source: https://formik.org/docs/tutorial
  const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);

    return (
      <Flex pb="0rem" justifyContent={isMobile ? "flex-start" : "space-around"}>
        <StatusReportLabel htmlFor={props.id || props.name} label={label} />
        <Box width="60vw">
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
          <Input
            {...field}
            {...props}
            disabled={
              label === "Comments" || label === "Pathology"
                ? props.disabled
                : isFormDisabled
            }
            height="42px"
            style={{ width: isMobile ? "40vw" : "20vw" }}
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            padding="0.5rem 0.75rem"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
        </Box>
      </Flex>
    );
  };

  // Text input with the unit of measurement next to it
  const TextInputWithUOM = ({ uom, label, ...props }) => {
    const [field] = useField(props);

    return (
      <Flex pb="1rem" direction="column" justifyContent="flex-end">
        <label htmlFor={props.id || props.name} className="measurementLabel">
          {label ? label : props.name} {uom ? `(${uom})` : null}
        </label>
        <input
          {...field}
          {...props}
          id={props.name}
          style={{
            width: isMobile ? "15vw" : "5vw",
            borderRadius: "14px",
            boxSizing: "border-box",
            padding: "0.5rem 0.75rem",
            border: "2px solid lightgray",
            color: patientData?.abnormalValues?.[field.name]
              ? patientData.studyStatus === "Signed"
                ? "rgba(255,0,0,.4)"
                : "rgba(255,0,0,.8)"
              : patientData.studyStatus === "Signed"
              ? "rgba(0, 0, 0, .4)"
              : "",
            backgroundColor: field.value ? "" : "#FFB6C1",
          }}
          disabled={isFormDisabled}
        />
      </Flex>
    );
  };

  // Source: https://formik.org/docs/tutorial
  const ReferringPhysicianTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    const { values } = useFormikContext();

    if (field.name !== "newReferringPhysician") {
      return null;
    }

    if (values.ReferringPhysicianId === "add") {
      return (
        <Flex pb="1rem" justifyContent="start">
          <StatusReportLabel
            htmlFor={props.id || props.name}
            label={label}
            style={{ color: "red" }}
          />
          <Box width="60vw">
            {meta.touched && meta.error ? (
              <Box color="red">{meta.error}</Box>
            ) : null}
            <Input
              {...field}
              {...props}
              disabled={isFormDisabled}
              height="42px"
              boxSizing="border-box"
              borderRadius="14px"
              border="2px solid #d3d3d3"
              padding="0.5rem 0.75rem"
              _focus={{ outline: "none", border: "2px solid #0038ff" }}
            />
          </Box>
        </Flex>
      );
    }

    return null;
  };

  const TextAreaField = ({ setHovered, label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const protocolName = patientData.ProtocolName?.toLowerCase();
    const isStressEcho = protocolName?.includes("stress")
      ? true
      : protocolName?.includes("dobutamine")
      ? true
      : false;

    return (
      <div>
        <label
          id={props.name}
          style={{ fontSize: isStressEcho ? "24px" : "18px" }}
        >
          {label}
        </label>
        <div>
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
          <Textarea
            rows="8"
            {...field}
            {...props}
            disabled={isFormDisabled}
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            padding="0.5rem 0.75rem"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </div>
      </div>
    );
  };

  function getProtocolName(protocolName) {
    const protocol = protocolName?.toLowerCase();

    if (protocol?.includes("free")) {
      return "free";
    } else if (protocol?.includes("pediatric")) {
      return "pediatric";
    } else if (protocol?.includes("stress")) {
      return "stress";
    } else if (protocol?.includes("dobutamine")) {
      return "dobutamine";
    }
    return "free";
  }

  const StudyTypeSelect = () => {
    const [protocolName, setProtocolName] = useState(
      getProtocolName(patientData?.ProtocolName)
    );

    function handleProtocolNameChange(event) {
      setProtocolName(event.target.value);
    }

    return (
      <Box width="15vw" marginTop="1rem">
        <ModalWithGrid
          buttonVariant="link"
          buttonColor="#0038ff"
          protocolName={protocolName}
          setPatientData={setPatientData}
          buttonText="CHANGE STUDY TYPE"
          headingText="CHANGE STUDY TO STRESS ECHO TYPE"
          SeriesInstanceUID={patientData?.SeriesInstanceUID}
        >
          <VStack spacing={4} align="left" width="100%">
            <label htmlFor="studyType">Select study type</label>
            <Select
              id="studyType"
              height="42px"
              width="15vw"
              boxSizing="border-box"
              borderRadius="14px"
              border="2px solid #d3d3d3"
              value={protocolName}
              disabled={isFormDisabled}
              onChange={(event) => handleProtocolNameChange(event)}
              _focus={{ outline: "none", border: "2px solid #0038ff" }}
            >
              <option value="free">Free form echo</option>
              <option value="pediatric">Pediatric echo</option>
              <option value="stress">Stress echo</option>
              <option value="dobutamine">Pharmacological stress echo</option>
            </Select>
          </VStack>
        </ModalWithGrid>
      </Box>
    );
  };

  const TextAreaFieldFullWidth = ({ label, setHovered, ...props }) => {
    const [field, meta, helpers] = useField(props);

    const isStressEcho = protocolName?.includes("stress")
      ? true
      : protocolName?.includes("dobutamine")
      ? true
      : false;

    const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    return (
      <Flex pb="2rem" justifyContent="space-around">
        <Flex direction="column" width="22vw">
          <StatusReportLabel htmlFor={props.id || props.name} label={label} />

          {/*  Source for opening the Echo viewer in a new window instead of new tab
          https://stackoverflow.com/questions/50763356/react-router-open-route-in-new-window/50763599 */}

          {patientData.StudyInstanceUID && field.name === "testConclusion" && (
            <PrimaryButton
              onClick={() => {
                addAuditData(userattr, patientData, "echo rviewer open");

                async function openViewer() {
                  var strClientAccessToken =
                    getCookieWithSuffix(".accessToken");

                  const rviewerurl =
                    `${userattr["custom:gateway"]}/rviewer/viewer/?StudyInstanceUIDs=` +
                    patientData.StudyInstanceUID +
                    `&gateway=${encodeURIComponent(
                      userattr["custom:gateway"]
                    )}`;

                  window.open(
                    rviewerurl,
                    "_blank",
                    "toolbar=0,location=0,menubar=0"
                  );

                  try {
                    await fetch(rviewerurl, {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${strClientAccessToken}`, // Send token here
                      },
                      credentials: "include", // Ensure cookies from NGINX are set in the browser
                    });
                  } catch (error) {
                    console.error("Error during authorization fetch:", error);
                  }
                }
                openViewer();
              }}
              style={{ marginTop: "1rem", cursor: "pointer" }}

              /*                window.open(
                  `https://echoarbe.futurepacs.com/viewer/?StudyInstanceUIDs=` +
                    patientData.StudyInstanceUID +
                    `&gateway=${encodeURIComponent(user["custom:gateway"])}`,
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                );
*/
            >
              VIEW ECHO
            </PrimaryButton>
          )}

          {patientData.StudyInstanceUID &&
            field.name === "testConclusion" &&
            userattr["custom:clinicName"]?.includes("MK") && (
              <PrimaryButton
                onClick={() => {
                  addAuditData(userattr, patientData, "echo mviewer open");

                  async function openViewer() {
                    var strClientAccessToken =
                      getCookieWithSuffix(".accessToken");

                    const mviewerurl = `${userattr["custom:gateway"]}/viewer/${patientData.StudyInstanceUID}`;

                    window.open(
                      mviewerurl,
                      "_blank",
                      "toolbar=0,location=0,menubar=0"
                    );

                    try {
                      await fetch(mviewerurl, {
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${strClientAccessToken}`, // Send token here
                        },
                        credentials: "include", // Ensure cookies from NGINX are set in the browser
                      });
                    } catch (error) {
                      console.error("Error during authorization fetch:", error);
                    }
                  }
                  openViewer();
                }}
                style={{ marginTop: "1rem", cursor: "pointer" }}
              >
                MK VIEW ECHO
              </PrimaryButton>
            )}

          {patientData.StudyInstanceUID &&
            field.name === "testConclusion" &&
            isStressEcho && (
              <PrimaryButton
                onClick={() => {
                  addAuditData(
                    userattr,
                    patientData,
                    "stress echo viewer open"
                  );

                  async function openViewer() {
                    var strClientAccessToken =
                      getCookieWithSuffix(".accessToken");

                    const seviewerurl =
                      `${userattr["custom:gateway"]}/rviewer/stressecho/?StudyInstanceUIDs=` +
                      patientData.StudyInstanceUID +
                      `&gateway=${encodeURIComponent(
                        userattr["custom:gateway"]
                      )}`;

                    window.open(
                      seviewerurl,
                      "_blank",
                      "toolbar=0,location=0,menubar=0"
                    );

                    try {
                      await fetch(seviewerurl, {
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${strClientAccessToken}`, // Send token here
                        },
                        credentials: "include", // Ensure cookies from NGINX are set in the browser
                      });
                    } catch (error) {
                      console.error("Error during authorization fetch:", error);
                    }
                  }
                  openViewer();

                  /*
                  window.open(
                    `https://echoarbe.futurepacs.com/stressecho/?StudyInstanceUIDs=` +
                      patientData.StudyInstanceUID +
                      `&gateway=${encodeURIComponent(user["custom:gateway"])}`,
                    "_blank",
                    "toolbar=0,location=0,menubar=0"
                  );
*/
                }}
                style={{ marginTop: "1rem", cursor: "pointer" }}
              >
                VIEW STRESS ECHO
              </PrimaryButton>
            )}

          {field.name === "testConclusion" && <StudyTypeSelect />}
        </Flex>
        <Box width="60vw">
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
          <Textarea
            rows={field.name === "testConclusion" ? "8" : "3"}
            {...field}
            {...props}
            disabled={isFormDisabled}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            padding="0.5rem 0.75rem"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
        </Box>
      </Flex>
    );
  };

  const SelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <Flex pb="0rem" justifyContent="start">
        {field?.name !== "ReferringPhysicianId" && (
          <StatusReportLabel label={label} htmlFor={props.id || props.name} />
        )}
        {field?.name === "ReferringPhysicianId" && (
          <>
            <StatusReportLabel label={label} htmlFor={props.id || props.name} />
            {/*}
            <RouterLink to="/referringphysicians">
              <SecondaryButton>MANAGE REFERRING PHYSICIANS</SecondaryButton>
            </RouterLink>
*/}
          </>
        )}

        <Select
          width="60vw"
          {...field}
          {...props}
          disabled={isFormDisabled}
          height="42px"
          boxSizing="border-box"
          borderRadius="14px"
          border="2px solid #d3d3d3"
          _focus={{ outline: "none", border: "2px solid #0038ff" }}
        />
        {meta.touched && meta.error ? (
          <Box color="red">{meta.error}</Box>
        ) : null}
      </Flex>
    );
  };

  const GenderSelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const { values } = useFormikContext();

    return (
      <Flex pb="0rem" justifyContent="space-around">
        <StatusReportLabel
          label={label}
          style={!patientData.PatientSex && { color: "red" }}
          htmlFor={props.id || props.name}
        />
        <Flex width="60vw" flexDirection="column">
          <Select
            width="100%"
            {...field}
            {...props}
            disabled={isFormDisabled}
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
          {!patientData.PatientSex && (
            <Text color="red">
              Please select the gender to successfully save the form
            </Text>
          )}
        </Flex>
        {meta.touched && meta.error ? (
          <Box color="red">{meta.error}</Box>
        ) : null}
      </Flex>
    );
  };

  const DoBDatePicker = () => {
    const handleDateChange = (date) => {
      setPatientDOB(date);
    };

    return (
      <Flex pb="1rem" justifyContent="space-around">
        <>
          <StatusReportLabel
            label="Date of Birth"
            style={!patientData.PatientSex && { color: "red" }}
            htmlFor="PatientBirthDate"
          />
          <Flex width={isMobile ? "10vw" : "60vw"} flexDirection="column">
            <DatePicker
              id="PatientBirthDate"
              selected={patientDOB}
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(date) => handleDateChange(date)}
              disabled={isFormDisabled}
            />
            {!patientData.PatientBirthDate && (
              <Text color="red">
                Please select the Date of Birth to successfully save the form
              </Text>
            )}
          </Flex>
        </>
      </Flex>
    );
  };

  const SignAndSaveDatePicker = () => {
    const handleDateChange = (date) => {
      setNewSignedDate(date);
      setIsSignedDateSelected(true);
    };

    return (
      <Flex pb="2rem" justifyContent="flex-start">
        <StatusReportLabel label="New Signed Date" htmlFor="SignedDate" />
        <Flex width="200px" flexDirection="column">
          <DatePicker
            id="SignedDate"
            selected={newSignedDate}
            dateFormat="yyyy-MM-dd h:mm aa"
            timeInputLabel="Time:"
            showTimeInput
            // showMonthDropdown
            // showYearDropdown
            dropdownMode="select"
            onChange={(date) => handleDateChange(date)}
          />
        </Flex>
      </Flex>
    );
  };

  const CustomTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
      <>
        <StatusReportLabel htmlFor={field.name} label={label} />
        <div>
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
          <Input
            {...field}
            {...props}
            disabled={isFormDisabled}
            width="60vw"
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            padding="0.5rem 0.75rem"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
        </div>
      </>
    );
  };

  const CustomTextAreaField = ({ ...props }) => {
    const [field, meta] = useField(props);

    return (
      <>
        <StatusReportLabel htmlFor="contrastAgentNotes" label={props.label} />
        <div>
          <Box width="60vw">
            {meta.touched && meta.error ? (
              <Box color="red">{meta.error}</Box>
            ) : null}
            <Textarea
              rows="8"
              {...field}
              {...props}
              disabled={isFormDisabled}
              boxSizing="border-box"
              borderRadius="14px"
              border="2px solid #d3d3d3"
              padding="0.5rem 0.75rem"
              _focus={{ outline: "none", border: "2px solid #0038ff" }}
            />
          </Box>
        </div>
      </>
    );
  };

  const DynamicSelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const { values } = useFormikContext();

    if (values.studyQuality !== "tds" && field.name === "tdsReason") {
      return null;
    }

    return (
      <Flex width="100%" direction="column">
        <Flex pb="1rem" justify="space-around">
          <StatusReportLabel htmlFor={props.id || props.name} label={label} />
          <Select
            {...field}
            {...props}
            disabled={isFormDisabled}
            width="60vw"
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
        </Flex>

        {values.tdsReason === "other" && field.name === "tdsReason" && (
          <Flex pb="2rem" justify="space-around">
            <CustomTextInput
              name="otherTdsReason"
              id="otherTdsReason"
              label="Specify TDS Reason"
            />
          </Flex>
        )}

        {values.Rhythm === "other" && field.name === "Rhythm" && (
          <Flex pb="2rem" justify="space-around">
            <CustomTextInput
              name="otherRhythm"
              id="otherRhythm"
              label="Specify Rhythm"
            />
          </Flex>
        )}

        {values.reasonForTest === "other" && field.name === "reasonForTest" && (
          <Flex pb="2rem" justify="space-around">
            <CustomTextInput
              name="reasonForTestOther"
              id="reasonForTestOther"
              label="Specify Reason for Test"
            />
          </Flex>
        )}

        {values.comparisonPriors === "other" &&
          field.name === "comparisonPriors" && (
            <Flex pb="2rem" justify="space-around">
              <CustomTextInput
                name="comparisonPriorsOther"
                id="comparisonPriorsOther"
                label="When compared to priors"
              />
            </Flex>
          )}
      </Flex>
    );
  };

  // Similar to DynamicSelectInput component
  const StressEchoType = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const { values } = useFormikContext();

    return (
      <Flex width="100%" direction="column">
        <Flex pb="2rem" justify="space-around">
          <StatusReportLabel htmlFor={props.id || props.name} label={label} />
          <Select
            {...field}
            {...props}
            disabled={isFormDisabled}
            width="60vw"
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
          {meta.touched && meta.error ? (
            <Box color="red">{meta.error}</Box>
          ) : null}
        </Flex>

        {values.stressEchoType === "pharmacological" && (
          <Flex pb="2rem" justify="space-around">
            <CustomTextInput
              name="pharmacologicalNotes"
              id="pharmacologicalNotes"
              label="Pharmacological Notes"
            />
          </Flex>
        )}
      </Flex>
    );
  };

  const ContrastAgentComponent = ({ label, ...props }) => {
    const { values } = useFormikContext();

    return (
      <Flex width="100%" direction="column">
        <Flex width="100%" pb="2rem" justify="space-around">
          <StatusReportLabel
            htmlFor={props.id || props.name}
            label="Contrast Agent Used?"
          />
          <Switch
            width="60vw"
            colorScheme="blue"
            size="lg"
            onChange={setIsContrastAgentUsed}
            isChecked={isContrastAgentUsedFlag}
          />
        </Flex>
        {isContrastAgentUsedFlag && (
          <Flex pb="2rem" justify="space-around">
            <CustomTextAreaField
              name="contrastAgentNotes"
              id="contrastAgentNotes"
              label="Contrast agent notes"
            />
          </Flex>
        )}
      </Flex>
    );
  };

  const StressEchoFindings = () => {
    return (
      <>
        <StressEchoType name="stressEchoType" label="Stress Echo Type">
          <option value="physiological">Physiological</option>
          <option value="pharmacological">Pharmacological</option>
        </StressEchoType>

        <TextAreaFieldFullWidth
          name="stressTestProtocol"
          label="Stress Test Protocol"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="reasonForStopping"
          label="Reason for Stopping"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="functionalCapacity"
          label="Functional Capacity"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="hemodynamicExerciseResponse"
          label="Hemodynamic Response to Exercise"
          setHovered={setIsHovered}
        />
      </>
    );
  };

  const PhysiologicalStressFindings = () => {
    return (
      <>
        <TextAreaFieldFullWidth
          name="baselineECG"
          label="Resting ECG"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="peakExerciseECG"
          label="Peak Exercise ECG"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="recoveryECG"
          label="Recovery ECG"
          setHovered={setIsHovered}
        />

        <TextAreaFieldFullWidth
          name="restingStressEcho"
          label="Resting Echo"
          setHovered={setIsHovered}
        />

        <TextAreaFieldFullWidth
          name="peakExerciseStressEcho"
          label="Peak Exercise Echo"
          setHovered={setIsHovered}
        />

        <TextAreaFieldFullWidth
          name="recoveryStressEcho"
          label="Recovery Echo"
          setHovered={setIsHovered}
        />
      </>
    );
  };

  const PharmacologicalStressFindings = () => {
    return (
      <>
        <TextAreaFieldFullWidth
          name="restingECGEcho"
          label="Resting ECG/Echo"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="lowDoseDobutamineECGEcho"
          label="Low Dose Dobutamine ECG/Echo"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="peakDoseDobutamineECGEcho"
          label="Peak Dose Dobutamine ECG/Echo"
          setHovered={setIsHovered}
        />
        <TextAreaFieldFullWidth
          name="recoveryECGEcho"
          label="Recovery ECG/Echo"
          setHovered={setIsHovered}
        />
      </>
    );
  };

  const resetReportTemplate = {
    lvSizeAndFunction: "",
    rvPaPv: "",
    laAndRa: "",
    aortaAndAv: "",
    mvAndTv: "",
    vessels: "",
    testConclusion: "",
    contrastAgentNotes: "",
  };

  function setReportTemplate(
    formFieldValues,
    setFieldValue,
    reportTemplate = resetReportTemplate
  ) {
    for (const [key, value] of Object.entries(reportTemplate)) {
      if (!formFieldValues[key]?.includes(value)) {
        if (formFieldValues[key]) {
          setFieldValue(key, formFieldValues[key] + " " + value);
        } else {
          setFieldValue(key, value);
        }
      }
    }
  }

  function getReportTemplateText(templateId) {
    return reportTemplates.find((et) => et.templateId === templateId)
      .templateText;
  }

  const TemplateSelectInput = ({ label, ...props }) => {
    const { setFieldValue, values } = useFormikContext();

    const handleChange = (event) => {
      const { value } = event?.target;

      if (value) {
        setReportTemplate(values, setFieldValue, getReportTemplateText(value));
      } else {
        setReportTemplate(values, setFieldValue);
      }
    };

    return (
      <Flex width="100%" pb="1rem" direction="column">
        <Flex justify="space-around">
          <div>
            <StatusReportLabel htmlFor={props.id || props.name} label={label} />
          </div>
          <Select
            {...props}
            width="60vw"
            height="42px"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            onChange={handleChange}
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
            disabled={isFormDisabled}
          />
        </Flex>
      </Flex>
    );
  };

  const ApplyNormalSentences = () => {
    const { setFieldValue, values } = useFormikContext();

    const normalValueSentenceKeys = {
      lvSizeAndFunction: ["EFSimpsons"],
      rvPaPv: [],
      laAndRa: [],
      aortaAndAv: [],
      mvAndTv: [],
      vessels: [],
      testConclusion: [],
      contrastAgentNotes: [],
    };

    useEffect(() => {
      const setNormalValueSentences = async () => {
        const normalValueMeasurements = await getAllNormalValueMeasurements();

        for (const nvm of normalValueMeasurements) {
          for (const [key, value] of Object.entries(normalValueSentenceKeys)) {
            const measurement = nvm.normalValueMeasurementName;

            if (
              !patientData?.abnormalValues?.[measurement] &&
              value.includes(measurement)
            ) {
              let fieldValue = nvm.normalValueSentence;

              if (fieldValue.includes("/m")) {
                const measurementValueAndUnit =
                  values[measurement] + patientData[measurement + "UOM"];

                fieldValue = fieldValue.replace("/m", measurementValueAndUnit);

                fieldValue = fieldValue + ".";
              }

              // to prevent duplicates
              if (!values[key].includes(fieldValue)) {
                setFieldValue(key, fieldValue + " " + values[key]);
              }
            }
          }
        }
      };

      setNormalValueSentences();
    }, []);

    return null;
  };

  const PrimaryMeasurementsHeader = () => {
    return (
      <Text
        m="2rem 0"
        fontFamily='"Jost"'
        fontStyle="normal"
        fontWeight="500"
        fontSize="18px"
        lineHeight="26px"
        letterSpacing="0.02em"
        borderBottom="2px solid black"
      >
        Primary measurements
      </Text>
    );
  };

  // Used for proper alignment of measurements as per the Report measurements document
  const EmptyGridElement = () => <div></div>;

  const getCustomDateOfBirth = (dob) => {
    let month = dob.getMonth();
    let date = dob.getDate();

    if (month + 1 <= 9) {
      month = "0" + (month + 1).toString();
    } else {
      month = (month + 1).toString();
    }

    if (date <= 9) {
      date = "0" + date.toString();
    } else {
      date = date.toString();
    }

    const dateString = dob.getFullYear().toString() + month + date;

    return dateString;
  };

  const FloatingInfo = ({ isTransparent }) => {
    return (
      <Box
        fontSize={{ base: "0.95rem", sm: "1.5rem" }}
        p=".75rem"
        //        color="whitesmoke"
        backgroundColor={
          userattr["custom:role"] === "educator" && isGoldReportFlag
            ? "#D4AF37"
            : studyStatus === "Test In Progress"
            ? "#FF0000"
            : "#111111"
        }
        borderRadius="10px"
        //        boxShadow="10px 10px 30px #bebebe, -10px -10px 30px #ffffff"
        style={{
          position: "fixed",
          top: "15vh",
          left: "1rem",
          zIndex: 1,
          backgroundColor: isTransparent ? "rgba(0, 0, 0, 0.05)" : "black",
          color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
        }}
      >
        {lastCommaFirst(patientData.PatientName)}
        {patientData.PatientSex ? ", " + patientData.PatientSex : ""}
        {patientData.PatientBirthDate
          ? ", " + calculateAge(patientData.PatientBirthDate)
          : ""}
        {customDateFormat(
          !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
        )
          ? ", " +
            customDateFormat(
              !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
            )
          : ""}
        {", " + patientData.studyStatus}
        {(userattr["custom:role"] === "educator" ||
          userattr["custom:role"] === "learner") &&
        patientData.LearnerID
          ? ", LearnerID " + patientData.LearnerID
          : null}
      </Box>
    );
  };

  const macroFields = [
    "testConclusion",
    "lvSizeAndFunction",
    "rvPaPv",
    "laAndRa",
    "aortaAndAv",
    "mvAndTv",
    "vessels",
    "otherNotes",
  ];

  function replaceMacros(values) {
    const modifiedFields = {};

    for (let i = 0; i < macroFields.length; i++) {
      let fieldValue = values[macroFields[i]];

      for (let j = 0; j < macros.length; j++) {
        fieldValue = fieldValue.replace(
          macros[j].macroCode,
          values[macros[j].measurementName] +
            (patientData[macros[j].measurementName + "UOM"]
              ? " " + patientData[macros[j].measurementName + "UOM"]
              : "")
        );
      }

      modifiedFields[macroFields[i]] = fieldValue;
    }

    return modifiedFields;
  }

  const ReferringPhysician = () => {
    if (patient.ReferringPhysicianName) {
      return (
        <TextInput
          name="ReferringPhysicianName"
          id="ReferringPhysicianName"
          label="Referring Physician"
        />
      );
    }

    return (
      <>
        <SelectInput label="Referring Physician" name="ReferringPhysicianId">
          <option value="">Select a Referring Physician</option>
          {referringPhysicians.map((rp) => (
            <option
              value={rp.referringPhysicianId}
              key={rp.referringPhysicianId}
            >
              {rp.referringPhysicianName}
            </option>
          ))}
          <option value="add">Add a new Referring Physician</option>
        </SelectInput>
        <ReferringPhysicianTextInput
          name="newReferringPhysician"
          id="newReferringPhysician"
          label="Add new Referring Physician"
        />
      </>
    );
  };

  const ReadingPhysician = () => {
    if (hasMatchingWord(userattr["custom:clinicName"], "MK")) {
      return (
        <SelectInput label="Reading Physician" name="PhysiciansOfRecord">
          <option value="">Select a Reading Physician</option>
          <option value="Dr. Ahmed Al Riyami">Dr. Ahmed Al Riyami</option>
          <option value="Dr. S. Zuberi">Dr. S. Zuberi</option>
          <option value="Dr. Eva Lonn">Dr. Eva Lonn</option>
        </SelectInput>
      );
    } else if (hasMatchingWord(userattr["custom:clinicName"], "prime")) {
      return (
        <SelectInput label="Reading Physician" name="PhysiciansOfRecord">
          <option value="">Select a Reading Physician</option>
          <option value="Dr. Khaled Salem">Dr. Khaled Salem</option>
          <option value="Dr. Karim Taha">Dr. Karim Taha</option>
          <option value="Dr. Vikrum Malhotra">Dr. Vikrum Malhotra</option>
          <option value="Dr. Kokab Awan">Dr. Kokab Awan</option>
        </SelectInput>
      );
    } else if (hasMatchingWord(userattr["custom:clinicName"], "garani")) {
      return (
        <SelectInput label="Reading Physician" name="PhysiciansOfRecord">
          <option value="">Select a Reading Physician</option>
          <option value="Dr. Osama Garani">Dr. Osama Garani</option>
        </SelectInput>
      );
    } else if (hasMatchingWord(userattr["custom:clinicName"], "Westville")) {
      return (
        <SelectInput label="Reading Physician" name="PhysiciansOfRecord">
          <option value="">Select a Reading Physician</option>
          <option value="Dr. K. Michael">Dr. K. Michael</option>
        </SelectInput>
      );
    } else {
      return (
        <TextInput
          name="PhysiciansOfRecord"
          id="PhysiciansOfRecord"
          label="Reading Physician"
        />
      );
    }
  };

  const Sonographer = () => {
    if (hasMatchingWord(userattr["custom:clinicName"], "prime")) {
      return (
        <SelectInput label="Sonographer" name="OperatorsName">
          <option value="">Select a Sonographer</option>
          <option value="Vadim Khachaturov">Vadim Khachaturov</option>
          <option value="Md Abu Arafat">Md Abu Arafat</option>
          <option value="Majid Shariatmadari">Majid Shariatmadari</option>
          <option value="Amjad Mahmood">Amjad Mahmood</option>
        </SelectInput>
      );
    } else if (hasMatchingWord(userattr["custom:clinicName"], "garani")) {
      return (
        <SelectInput label="Sonographer" name="OperatorsName">
          <option value="">Select a Sonographer</option>
          <option value="Justine Dyck">Justine Dyck</option>
        </SelectInput>
      );
    } else {
      return (
        <TextInput
          name="OperatorsName"
          id="OperatorsName"
          label="Sonographer"
        />
      );
    }
  };

  const GoldReport = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    const handleChange = () => {
      if (isGoldReportFlag) {
        setIsGoldReport(false);
        setFieldValue("isGoldReport", "f");
      } else {
        setIsGoldReport(true);
        setFieldValue("isGoldReport", "t");
      }
    };

    return (
      <Flex
        p="3rem"
        pb="0rem"
        justifyContent={isMobile ? "flex-start" : "space-around"}
      >
        <StatusReportLabel htmlFor={props.id} label={props.label} />
        <Switch
          {...field}
          width={isMobile ? "40vw" : "20vw"}
          colorScheme={meta.touched && meta.error ? "red" : "blue"}
          size="lg"
          onChange={handleChange}
          isChecked={isGoldReportFlag}
          disabled={isFormDisabled}
        />
      </Flex>
    );
  };

  function PreviousStudies({ label, ...props }) {
    const lastStudy = previousStudies[0];

    if (!lastStudy) {
      return null;
    }

    return (
      <Flex width="100%" direction="column">
        <Flex pb="1rem" justify="space-around">
          <StatusReportLabel
            htmlFor={props.id || props.name}
            label={label + " (" + customDateFormat(lastStudy.SeriesDate) + ")"}
          />
          <Flex direction="column" width="60vw">
            <Textarea
              rows="8"
              value={lastStudy.testConclusion}
              {...props}
              disabled
              readOnly
              boxSizing="border-box"
              borderRadius="14px"
              border="2px solid #d3d3d3"
              padding="0.5rem 0.75rem"
              _focus={{ outline: "none", border: "2px solid #0038ff" }}
            />
          </Flex>
        </Flex>
        <Flex pb="1rem" justify="space-around">
          <StatusReportLabel
            htmlFor={props.id || props.name}
            label="Previous Studies"
          />
          <Grid
            templateColumns="8rem 8rem"
            width="60vw"
            justifyItems="start"
            alignItems="baseline"
          >
            <RouterLink
              target="_blank"
              rel="noopener noreferrer"
              key={lastStudy.SeriesInstanceUID}
              to={`/patients/${lastStudy.SeriesInstanceUID}`}
            >
              <Text color="blue.400">
                {customDateFormat(lastStudy.SeriesDate)}↗
              </Text>
            </RouterLink>

            <ModalWithGrid
              buttonText="SHOW MORE"
              headingText="Previous Studies"
            >
              {previousStudies.map((study) => (
                <RouterLink
                  target="_blank"
                  rel="noopener noreferrer"
                  key={study.SeriesInstanceUID}
                  to={`/patients/${study.SeriesInstanceUID}`}
                >
                  <Text color="blue.400">
                    {customDateFormat(study.SeriesDate)}↗
                  </Text>
                </RouterLink>
              ))}
            </ModalWithGrid>
          </Grid>
        </Flex>
      </Flex>
    );
  }

  // Component to watch for certain field changes and to update related fields as necessary
  function WatchFormChanges() {
    const { touched, setFieldValue, values } = useFormikContext();

    useEffect(() => {
      const TRMG = parseInt(values.TRMG || 0);
      const RASP = parseInt(values.RASP || 0);
      const PAP = parseInt(values.PAP || 0);

      if (TRMG && RASP && PAP !== TRMG + RASP) {
        setFieldValue("PAP", TRMG + RASP);
      }
    }, [setFieldValue, values.PAP, values.RASP, values.TRMG]);

    useEffect(() => {
      const [
        basalAnterior,
        basalAnteroseptal,
        basalInferoseptal,
        basalInferior,
        basalInferolateral,
        basalAnterolateral,
        midAnterior,
        midAnteroseptal,
        midInferoseptal,
        midInferior,
        midInferolateral,
        midAnterolateral,
        apicalAnterior,
        apicalSeptal,
        apicalInferior,
        apicalLateral,
        apex,
      ] = [
        parseFloat(values.basalAnterior || 1),
        parseFloat(values.basalAnteroseptal || 1),
        parseFloat(values.basalInferoseptal || 1),
        parseFloat(values.basalInferior || 1),
        parseFloat(values.basalInferolateral || 1),
        parseFloat(values.basalAnterolateral || 1),
        parseFloat(values.midAnterior || 1),
        parseFloat(values.midAnteroseptal || 1),
        parseFloat(values.midInferoseptal || 1),
        parseFloat(values.midInferior || 1),
        parseFloat(values.midInferolateral || 1),
        parseFloat(values.midAnterolateral || 1),
        parseFloat(values.apicalAnterior || 1),
        parseFloat(values.apicalSeptal || 1),
        parseFloat(values.apicalInferior || 1),
        parseFloat(values.apicalLateral || 1),
        parseFloat(values.apex || 1),
      ];

      const arrBullseye = [
        basalAnterior,
        basalAnteroseptal,
        basalInferoseptal,
        basalInferior,
        basalInferolateral,
        basalAnterolateral,
        midAnterior,
        midAnteroseptal,
        midInferoseptal,
        midInferior,
        midInferolateral,
        midAnterolateral,
        apicalAnterior,
        apicalSeptal,
        apicalInferior,
        apicalLateral,
        apex,
      ];

      let sumarrBullseye = 0;

      // calculate sum using forEach() method
      arrBullseye.forEach((num) => {
        sumarrBullseye += num;
      });

      if (
        touched.basalAnterior ||
        touched.basalAnteroseptal ||
        touched.basalInferoseptal ||
        touched.basalInferior ||
        touched.basalInferolateral ||
        touched.basalAnterolateral ||
        touched.midAnterior ||
        touched.midAnteroseptal ||
        touched.midInferoseptal ||
        touched.midInferior ||
        touched.midInferolateral ||
        touched.midAnterolateral ||
        touched.apicalAnterior ||
        touched.apicalSeptal ||
        touched.apicalInferior ||
        touched.apicalLateral ||
        touched.apex
      ) {
        setFieldValue(
          "wmsi",
          Number(Math.round(sumarrBullseye / 17 + "e1") + "e-1")
        );
      }
    }, [
      touched.basalAnterior,
      touched.basalAnteroseptal,
      touched.basalInferoseptal,
      touched.basalInferior,
      touched.basalInferolateral,
      touched.basalAnterolateral,
      touched.midAnterior,
      touched.midAnteroseptal,
      touched.midInferoseptal,
      touched.midInferior,
      touched.midInferolateral,
      touched.midAnterolateral,
      touched.apicalAnterior,
      touched.apicalSeptal,
      touched.apicalInferior,
      touched.apicalLateral,
      touched.apex,
    ]);

    useEffect(() => {
      const [
        postbasalAnterior,
        postbasalAnteroseptal,
        postbasalInferoseptal,
        postbasalInferior,
        postbasalInferolateral,
        postbasalAnterolateral,
        postmidAnterior,
        postmidAnteroseptal,
        postmidInferoseptal,
        postmidInferior,
        postmidInferolateral,
        postmidAnterolateral,
        postapicalAnterior,
        postapicalSeptal,
        postapicalInferior,
        postapicalLateral,
        postapex,
      ] = [
        parseFloat(values.postbasalAnterior || 1),
        parseFloat(values.postbasalAnteroseptal || 1),
        parseFloat(values.postbasalInferoseptal || 1),
        parseFloat(values.postbasalInferior || 1),
        parseFloat(values.postbasalInferolateral || 1),
        parseFloat(values.postbasalAnterolateral || 1),
        parseFloat(values.postmidAnterior || 1),
        parseFloat(values.postmidAnteroseptal || 1),
        parseFloat(values.postmidInferoseptal || 1),
        parseFloat(values.postmidInferior || 1),
        parseFloat(values.postmidInferolateral || 1),
        parseFloat(values.postmidAnterolateral || 1),
        parseFloat(values.postapicalAnterior || 1),
        parseFloat(values.postapicalSeptal || 1),
        parseFloat(values.postapicalInferior || 1),
        parseFloat(values.postapicalLateral || 1),
        parseFloat(values.postapex || 1),
      ];

      const arrpostBullseye = [
        postbasalAnterior,
        postbasalAnteroseptal,
        postbasalInferoseptal,
        postbasalInferior,
        postbasalInferolateral,
        postbasalAnterolateral,
        postmidAnterior,
        postmidAnteroseptal,
        postmidInferoseptal,
        postmidInferior,
        postmidInferolateral,
        postmidAnterolateral,
        postapicalAnterior,
        postapicalSeptal,
        postapicalInferior,
        postapicalLateral,
        postapex,
      ];

      let sumarrpostBullseye = 0;

      // calculate sum using forEach() method
      arrpostBullseye.forEach((num) => {
        sumarrpostBullseye += num;
      });

      if (
        touched.postbasalAnterior ||
        touched.postbasalAnteroseptal ||
        touched.postbasalInferoseptal ||
        touched.postbasalInferior ||
        touched.postbasalInferolateral ||
        touched.postbasalAnterolateral ||
        touched.postmidAnterior ||
        touched.postmidAnteroseptal ||
        touched.postmidInferoseptal ||
        touched.postmidInferior ||
        touched.postmidInferolateral ||
        touched.postmidAnterolateral ||
        touched.postapicalAnterior ||
        touched.postapicalSeptal ||
        touched.postapicalInferior ||
        touched.postapicalLateral ||
        touched.postapex
      ) {
        setFieldValue(
          "postwmsi",
          Number(Math.round(sumarrpostBullseye / 17 + "e1") + "e-1")
        );
      }
    }, [
      touched.postbasalAnterior,
      touched.postbasalAnteroseptal,
      touched.postbasalInferoseptal,
      touched.postbasalInferior,
      touched.postbasalInferolateral,
      touched.postbasalAnterolateral,
      touched.postmidAnterior,
      touched.postmidAnteroseptal,
      touched.postmidInferoseptal,
      touched.postmidInferior,
      touched.postmidInferolateral,
      touched.postmidAnterolateral,
      touched.postapicalAnterior,
      touched.postapicalSeptal,
      touched.postapicalInferior,
      touched.postapicalLateral,
      touched.postapex,
    ]);

    return null;
  }

  function handleUpload() {
    setNumFilesUploaded(numFilesUploaded + 1);
    addAuditData(userattr, patient, "upload");
  }

  const processFile = ({ file, key }) => {
    const fileParts = key.split(".");
    const ext = fileParts.pop();
    return {
      file,
      // This will prepend a unix timestamp
      // to ensure all files uploaded are unique
      key: `${Date.now()} - ${fileParts.join(".")}.${ext}`,
    };
  };

  /*  const isStressEcho = protocolName?.includes("stress")
    ? true
    : protocolName?.includes("dobutamine")
    ? true
    : false;
*/

  const Mitral = () => (
    <div>
      <div onContextMenu={(event) => displayMenu(event, "mvAndTv")}>
        <TextAreaField
          name="mvAndTv"
          label="Mitral Valve and Tricuspid Valve"
          id="mvAndTv"
          setHovered={setIsHovered}
        />
        {userattr["custom:clinicName"] === "CNEAT" ? (
          <CNEATContextMenus menuid="mvAndTv" />
        ) : (
          <ContextMenus menuid="mvAndTv" />
        )}
      </div>
      <div className="measurementsGroup">
        <div className="measurements">
          <PrimaryMeasurementsHeader />
          <MeasurementsContainer>
            <TextInputWithUOM
              name="MVEA"
              label="E/A"
              uom={patientData.MVEAUOM}
            />
            <TextInputWithUOM name="E" uom={patientData.EUOM} />
            <TextInputWithUOM name="A" uom={patientData.AUOM} />
            <TextInputWithUOM
              name="DecT"
              label="Dec T"
              uom={patientData.DecTUOM}
            />
            <EmptyGridElement />
            <TextInputWithUOM name="Ee" label="E/e'" uom={patientData.EeUOM} />
            <TextInputWithUOM
              name="esep"
              label="e' sep"
              uom={patientData.esepUOM}
            />
            <TextInputWithUOM
              name="elat"
              label="e' Lat"
              uom={patientData.elatUOM}
            />
            <TextInputWithUOM name="IVRT" uom={patientData.IVRTUOM} />
            <EmptyGridElement />
            <EmptyGridElement />
            <TextInputWithUOM
              name="Eesep"
              label="E/e' sep"
              uom={patientData.EesepUOM}
            />
            <TextInputWithUOM
              name="Eelat"
              label="E/e' lat"
              uom={patientData.EelatUOM}
            />
            <EmptyGridElement />
            <EmptyGridElement />
            <TextInputWithUOM
              name="MVPHT"
              label="MV (PHT)"
              uom={patientData.MVPHTUOM}
            />
            <TextInputWithUOM
              name="MVTRCE"
              label="MV trace (VTI)"
              uom={patientData.MVTRCEUOM}
            />
            <TextInputWithUOM
              name="MVMRERO"
              label="MV MR ERO"
              uom={patientData.MVMREROUOM}
            />
            <EmptyGridElement />
            <EmptyGridElement />

            <TextInputWithUOM
              name="TRVel"
              label="TR max Vel"
              uom={patientData.TRVelUOM}
            />
            <TextInputWithUOM
              name="TRPG"
              label="TR PG"
              uom={patientData.TRPGUOM}
            />
            <TextInputWithUOM
              name="RASP"
              label="RAP"
              uom={patientData.RASPUOM}
            />
            <TextInputWithUOM name="RVSP" uom={patientData.RVSPUOM} />
            <EmptyGridElement />

            <TextInputWithUOM name="IVC" uom={patientData.IVCUOM} />
            <TextInputWithUOM
              name="TRMV"
              uom={patientData.TRMVUOM}
              label="TR mean Vel"
            />
            <TextInputWithUOM
              name="TRMG"
              uom={patientData.TRMGUOM}
              label="TR mean PG"
            />
            {/* <TextInputWithUOM
          name="RASP"
          label="RAP"
          uom={patientData.RASPUOM}
        /> */}
            <TextInputWithUOM name="PAP" uom={patientData.TRMGUOM} />
          </MeasurementsContainer>
        </div>
      </div>
    </div>
  );

  const Atria = () => (
    <div>
      <div onContextMenu={(event) => displayMenu(event, "laAndRa")}>
        <TextAreaField
          name="laAndRa"
          label={
            userattr["custom:clinicName"] === "CNEAT"
              ? "Diseases of the Atria"
              : "Left Atrium, Right Atrium and IAS"
          }
          id="laAndRa"
          setHovered={setIsHovered}
        />
        {userattr["custom:clinicName"] === "CNEAT" ? (
          <CNEATContextMenus menuid="laAndRa" />
        ) : (
          <ContextMenus menuid="laAndRa" />
        )}
      </div>
      <div className="measurementsGroup">
        <div className="measurements">
          <PrimaryMeasurementsHeader />
          <MeasurementsContainer>
            <TextInputWithUOM name="LAVI" uom={patientData.LAVIUOM} />
            <TextInputWithUOM name="LAd" uom={patientData.LAdUOM} />
            <TextInputWithUOM name="LAAO" label="LA / AO" uom={null} />
            <TextInputWithUOM name="LAV" label="LAV" uom={patientData.LAVUOM} />
            <EmptyGridElement />
            <TextInputWithUOM name="RAVI" uom={patientData.RAVIUOM} />
            <TextInputWithUOM
              name="RAa"
              id="raa"
              label="RA (area)"
              uom={patientData.RAaUOM}
            />
            <TextInputWithUOM name="RAV" uom={patientData.RAVUOM} />
            <EmptyGridElement />
            <EmptyGridElement />
            <TextInputWithUOM name="s" label="s" uom={patientData.sUOM} />
            <TextInputWithUOM name="d" label="d" uom={patientData.dUOM} />
            <TextInputWithUOM name="a" label="a" uom={patientData.aUOM} />
            <TextInputWithUOM
              name="adur"
              label="a dur"
              uom={patientData.adurUOM}
            />
            <TextInputWithUOM
              name="sdratio"
              label="s/d ratio"
              uom={patientData.sdratioUOM}
            />
          </MeasurementsContainer>
          <SecondaryMeasurementsContainer>
            <TextInputWithUOM
              name="LAVIA4"
              label="LAVI A4"
              uom={patientData.LAVIA4UOM}
            />
            <TextInputWithUOM
              name="LAVA4"
              label="LAV A4"
              uom={patientData.LAVA4UOM}
            />
            <TextInputWithUOM
              name="LAAsA4"
              label="LAAs A4"
              uom={patientData.LAAsA4UOM}
            />
            <TextInputWithUOM
              name="LALsA4"
              label="LALs A4"
              uom={patientData.LALsA4UOM}
            />
            <EmptyGridElement />
            <TextInputWithUOM
              name="LAVIA2"
              label="LAVI A2"
              uom={patientData.LAVIA2UOM}
            />
            <TextInputWithUOM
              name="LAVA2"
              label="LAV A2"
              uom={patientData.LAVA2UOM}
            />
            <TextInputWithUOM
              name="LAAsA2"
              label="LAAs A2"
              uom={patientData.LAAsA2UOM}
            />
            <TextInputWithUOM
              name="LALsA2"
              label="LALs A2"
              uom={patientData.LALsA2UOM}
            />
            {/* <TextInputWithUOM
          name="AO"
          uom={null}
        /> */}
            <EmptyGridElement />
          </SecondaryMeasurementsContainer>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          const clinicData = {
            clinicName: userattr["custom:clinicName"],
            street: userattr["custom:clinicStreet"],
            city: userattr["custom:clinicCity"],
            province: userattr["custom:clinicProvince"],
            country: userattr["custom:clinicCountry"],
            postalCode: userattr["custom:clinicPostalCode"],
            phoneNumber: userattr["custom:clinicPhoneNumber"],
            faxNumber: userattr["custom:clinicFaxNumber"],
            logo: userattr["custom:clinicLogo"],
          };

          const auditData = {
            accessType: accessType,
            user: userattr.email,
            role: userattr["custom:role"],
            signatureName: userattr["custom:signatureName"],
            time: new Date(),
            clinic: clinicData,
            // TODO: Include change of PatientBirthDate, isGoldReport
            changes: findFormChanges(values),
          };

          const fieldsWithMacros = replaceMacros(values);

          // spreading the values object after the patientData state updates the data with the changes made in the form
          const bodyData = {
            ...patientData,
            ...values,
            ...fieldsWithMacros,
            newSignedDate: isSignedDateSelected ? newSignedDate : "",
            ProtocolName: patientData.ProtocolName
              ? patientData.ProtocolName
              : patient.ProtocolName,
            PatientBirthDate: getCustomDateOfBirth(patientDOB),
            isContrastAgentUsed: isContrastAgentUsedFlag,
            isZScoreTableDisplayed: isZScoreTableDisplayed,
            zScore: { ...zScoreData },
            isBullsEyeTableDisplayed: isBullsEyeTableDisplayed,
            isGoldReport: isGoldReportFlag,
            bullsEye: { ...bullsEyeData },
            audit: [...patientData.audit, auditData],
            lastModifiedByUser: {
              email: userattr.email,
              role: userattr["custom:role"],
              time: new Date(),
            },
            pharmacologicalNotes:
              values.stressEchoType === "pharmacological"
                ? values.pharmacologicalNotes
                : "",
            /*            studyStatus: studyStatus === "Signed" ? "Signed" : "In progress", */
            studyStatus:
              studyStatus === "Signed"
                ? "Signed"
                : studyStatus === "Archived"
                ? "Archived"
                : studyStatus === "Amended"
                ? "Amended"
                : studyStatus === "In progress"
                ? "In progress"
                : "Test In Progress",
            otherTdsReason:
              values.tdsReason === "other" ? values.otherTdsReason : "",
            otherRhythm: values.Rhythm === "other" ? values.otherRhythm : "",
          };

          let newReferringPhysician = null;
          if (values.ReferringPhysicianId === "add") {
            newReferringPhysician = await createReferringPhysician({
              referringPhysicianName: values.newReferringPhysician,
            });

            bodyData.ReferringPhysicianId = newReferringPhysician._id
              ? newReferringPhysician.referringPhysicianId
              : values.ReferringPhysicianId;
          }

          const updatedData = await updateSeries(
            bodyData,
            patient.SeriesInstanceUID
          );

          if (updatedData._id) {
            setFormSubmitSuccess(true);

            // update Referring physician list
            if (newReferringPhysician && newReferringPhysician._id) {
              setReferringPhysicians((prevState) => [
                ...prevState,
                newReferringPhysician,
              ]);
            }

            setPatientData(updatedData);
            resetForm({ values: patientData });

            setTimeout(() => {
              setFormSubmitSuccess(false);
            }, 2000);
          }
        }}
      >
        <Form>
          {/* PromptIfDirty prevents user from leaving form with unsaved data */}
          <PromptIfDirty />
          <FloatingButtons
            macros={macros}
            patient={patientData}
            setStudyStatus={setStudyStatus}
            setAccessType={setAccessType}
            user={userattr}
            isTransparent={isHovered}
          />
          <FloatingInfo isTransparent={isHovered} />
          <ScrollTopButton />
          <WatchFormChanges />
          {/* <ApplyNormalSentences /> */}

          {/*          <Flex mt="2rem" justifyContent="center" alignItems="center">
            <Heading
              as="h1"
              fontFamily="Jost"
              fontSize="55px"
              fontWeight="500"
              lineHeight="70px"
            >
              Echocardiogram Report
            </Heading>
          </Flex>
*/}

          {userattr["custom:role"] === "educator" && (
            <GoldReport
              label="Gold Report?"
              name="GoldReport"
              id="GoldReport"
            />
          )}

          <Flex direction="column">
            {userattr["custom:role"] !== "learner" && (
              <>
                <S3AmplifyFileList
                  SeriesInstanceUID={patient.SeriesInstanceUID}
                  numFilesUploaded={numFilesUploaded}
                />
                <br />
                <hr />
                <br />
              </>
            )}

            <DemographicsContainer>
              <TextInput
                name="PatientName"
                id="PatientName"
                label="Patient Name"
              />
              {userattr["custom:clinicName"] === "CNEAT" &&
                patientData.PatientAge && (
                  <TextInput name="PatientAge" id="PatientAge" label="Age" />
                )}
              <GenderSelectInput label="Gender" name="PatientSex">
                <option value="">Select patient gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </GenderSelectInput>

              {!userattr["custom:clinicName"] === "CNEAT" && <DoBDatePicker />}

              {/* replaced by Save floating button
              {(!patientData.PatientSex || !patientData.PatientBirthDate) && (
                <SecondaryButton type="submit">UPDATE</SecondaryButton>
              )}
                */}

              {userattr["custom:clinicName"] !== "CNEAT" && (
                <ReferringPhysician />
              )}

              {userattr["custom:clinicName"] !== "CNEAT" && (
                <ReadingPhysician />
              )}

              {userattr["custom:clinicName"] !== "CNEAT" && <Sonographer />}

              <TextInput name="BSA" id="BSA" label="BSA (m2)" />
              <TextInput
                name="PatSize"
                id="PatSize"
                label={
                  patient.PatSizeUOM
                    ? "Height (" + patient.PatSizeUOM + ")"
                    : "Height (m)"
                }
              />
              <TextInput name="PatWeight" id="PatWeight" label="Weight (kg)" />
              <TextInput name="BPs" id="BPs" label="BPs (mmHg)" />
              <TextInput name="BPd" id="BPd" label="BPd (mmHg)" />
              <TextInput
                name="HR"
                id="HR"
                label={"HR (" + patientData.HRUOM + ")"}
              />
              {/*              <EmptyGridElement />*/}
              <TextInput
                name="Comments"
                id="Comments"
                label="Comments"
                disabled={false}
              />
              <TextInput name="DFA" id="DFA" label="Diastolic Function" />
              {hasMatchingWord(userattr["custom:clinicName"], "prime") ? (
                <SelectInput label="Clinic Location" name="clinicLocation">
                  <option value="prime">Prime Medical Centre</option>
                  <option value="hearthealth">
                    HeartHealth Medical Centre
                  </option>
                </SelectInput>
              ) : null}
              {((userattr["custom:role"] === "learner" &&
                !patient.isGoldReport) ||
                userattr["custom:role"] === "educator" ||
                userattr["custom:role"] === "sonographer" ||
                userattr["custom:role"] === "cardiologist") && (
                <div onContextMenu={(event) => displayMenu(event, "pathology")}>
                  <TextInput
                    name="Pathology"
                    id="Pathology"
                    label="Pathology"
                    disabled={
                      userattr["custom:role"] === "learner" ? true : false
                    }
                  />
                  {userattr["custom:role"] !== "learner" && (
                    <ContextMenus menuid="pathology" />
                  )}
                </div>
              )}
              {"\n"}
            </DemographicsContainer>
            <hr />
            <br />
            <DynamicSelectInput label="Reason for Test" name="reasonForTest">
              <option value="">Select a reason for test</option>
              <option value="other">Other</option>
              <option value="murmur">Murmur</option>
              <option value="stenosis">Native Valvular Stenosis</option>
              <option value="regurgitation">
                Native Valvular Regurgitation
              </option>
              <option value="mvp">Known / Suspected MVP</option>
              <option value="congenital">
                Congenital / Inherited Cardiac Structural Disease
              </option>
              <option value="prosthetic">Prosthetic Valves</option>
              <option value="endocarditis">Infective Endocarditis</option>
              <option value="pericardial">Pericardial Disease</option>
              <option value="cardiacmass">Cardiac Mass</option>
              <option value="interventional">Interventional Procedures</option>
              <option value="pulmonary">Pulmonary Disease</option>
              <option value="chestpain">Chest Pain</option>
              <option value="cad">CAD</option>
              <option value="dyspnea">Dyspnea</option>
              <option value="edema">Edema</option>
              <option value="cardiomyopathy">Cardiomyopathy</option>
              <option value="hypertension">Hypertension</option>
              <option value="thoracic">Thoracic Aortic Disease</option>
              <option value="neurologic">
                Neurologic / other embolic event
              </option>
              <option value="arrhythmia">Arrhythmia</option>
              <option value="syncope">Syncope</option>
              <option value="palpitations">Palpitations</option>
              <option value="precardio">Pre-Cardioversion</option>
              <option value="structural">
                Suspected Structural Heart Disease
              </option>
              <option value="tee">Indications for TEE</option>
              <option value="se">Indications for SE</option>
            </DynamicSelectInput>
            <DynamicSelectInput label="Study Quality" name="studyQuality">
              <option value="">Select a study quality</option>
              <option value="good">Good</option>
              <option value="adequate">Adequate</option>
              <option value="tds">Technically Difficult Study (TDS)</option>
            </DynamicSelectInput>
            <DynamicSelectInput label="TDS Reason" name="tdsReason">
              <option value="">Select a reason</option>
              <option value="bodyHabitus">Body Habitus</option>
              <option value="diagnosticImagingNotObtainable">
                Diagnostic imaging not obtainable
              </option>
              <option value="suboptimalAcousticWindow">
                Suboptimal acoustic window
              </option>
              <option value="agitatedPatient">Agitated patient</option>
              <option value="other">Other</option>
            </DynamicSelectInput>
            {userattr["custom:clinicName"] === "CNEAT" ? (
              <DynamicSelectInput label="Heart Rhythm" name="Rhythm">
                <option value="">Select a rhythm</option>
                <option value="sinus">Sinus</option>
                <option value="PVC">with PVCs</option>
                <option value="PAC">with PACs</option>
                <option value="paced">Paced</option>
                <option value="AF">AF / Aflutter</option>
                <option value="prolongedQT">Prolonged QT</option>
                <option value="bbb">BBB</option>
                <option value="svt">SVT</option>
                <option value="1AVB">1 AVB</option>
                <option value="2AVB">2 AVB</option>
                <option value="3AVB">3 AVB</option>
                <option value="RR">R-R Interval variability</option>
                <option value="indeterminate">Indeterminate</option>
              </DynamicSelectInput>
            ) : (
              <DynamicSelectInput label="Rhythm" name="Rhythm">
                <option value="">Select a rhythm</option>
                <option value="sinus">Sinus</option>
                <option value="sinusTachy">Sinus Tachycardia</option>
                <option value="sinusBrady">Sinus Bradycardia</option>
                <option value="sinuswithbeats">Sinus with extra beats</option>
                <option value="atrialFibrillation">Atrial fibrillation</option>
                <option value="paced">Paced</option>
                <option value="avPaced">A-V Paced</option>
                <option value="vPaced">V Paced</option>
                <option value="undetermined">Undetermined</option>
                <option value="bradycardia">Bradycardia</option>
                <option value="tachycardia">Tachycardia</option>
                <option value="atrialFlutter">Atrial flutter</option>
                <option value="freqAPB">Frequent APBs</option>
                <option value="freqVPB">Frequent VPBs</option>
                <option value="svtRuns">SVT runs</option>
                <option value="ventricularEctopic">Ventricular Ectopic</option>
                <option value="atrialEctopic">Atrial Ectopic</option>
                <option value="wpw">WPW</option>
                <option value="sss">SSS</option>
                <option value="prolongedQT">Prolonged QT</option>
                <option value="other">Other</option>
              </DynamicSelectInput>
            )}
            <TemplateSelectInput label="Select Report Template">
              <option value="">Select a report template</option>
              {reportTemplates
                .filter((rt) => {
                  const protocol = getProtocolName(patientData?.ProtocolName);

                  if (protocol === "free" || protocol === "pediatric") {
                    return rt.templateType === "free";
                  } else {
                    return rt.templateType === protocol;
                  }
                })
                .map((et) => (
                  <option value={et.templateId} key={et.templateId}>
                    {et.templateName}
                  </option>
                ))}
            </TemplateSelectInput>
            <div
              onContextMenu={(event) => displayMenu(event, "testConclusion")}
            >
              <TextAreaFieldFullWidth
                name="testConclusion"
                label="Test Conclusion"
                id="testConclusion"
                setHovered={setIsHovered}
              />
              {userattr["custom:clinicName"] === "CNEAT" ? (
                <CNEATContextMenus menuid="testConclusion" />
              ) : (
                <ContextMenus menuid="testConclusion" />
              )}
            </div>
            <DynamicSelectInput
              label="Comparison to Priors"
              name="comparisonPriors"
            >
              <option value="">Are prior studies available?</option>
              <option value="nopriors">
                No prior studies available for comparison
              </option>
              <option value="other">Prior studies available</option>
            </DynamicSelectInput>

            <PreviousStudies
              label="Prior Study Conclusion"
              name="previousStudies"
            />

            <ContrastAgentComponent />

            {patientData.ProtocolName?.toLowerCase().includes("stress") && (
              <>
                <StressEchoFindings />
                <PhysiologicalStressFindings />
              </>
            )}

            {patientData.ProtocolName?.toLowerCase().includes("dobutamine") && (
              <>
                <StressEchoFindings />
                <PharmacologicalStressFindings />
              </>
            )}
          </Flex>

          <Grid
            templateColumns="95vw"
            rowGap="1rem"
            paddingBottom="20pt"
            display={{ base: "none", md: "flex" }}
          >
            <Flex direction="column">
              <Flex width="100%" pb="2rem" justify="space-around">
                <StatusReportLabel
                  htmlFor="bullsEyeDisplaySwitch"
                  label="Display Bull's Eye table?"
                  style={{ paddingBottom: 0 }}
                />
                <Switch
                  width="60vw"
                  colorScheme="blue"
                  size="lg"
                  id="bullsEyeDisplaySwitch"
                  onChange={setIsBullsEyeTableDisplayed}
                  isChecked={isBullsEyeTableDisplayed}
                  disabled={isFormDisabled}
                />
              </Flex>
              {isBullsEyeTableDisplayed && (
                <BullsEyeTable
                  patientData={patientData}
                  bullsEyeData={bullsEyeData}
                  setBullsEyeData={setBullsEyeData}
                />
              )}
            </Flex>
          </Grid>

          <Grid
            mb="3vh"
            gap="3vw"
            templateColumns={isMobile ? "45vw" : "45vw 45vw"}
          >
            <div>
              <div
                onContextMenu={(event) =>
                  displayMenu(event, "lvSizeAndFunction")
                }
              >
                <TextAreaField
                  name="lvSizeAndFunction"
                  label={
                    userattr["custom:clinicName"] === "CNEAT"
                      ? "LV size, structure and function"
                      : userattr["custom:clinicName"]?.includes("PACE")
                      ? "Left Ventricle - Size and Function"
                      : "Left Ventricle and IVS"
                  }
                  id="lvSizeAndFunction"
                  setHovered={setIsHovered}
                />
                {userattr["custom:clinicName"] === "CNEAT" ? (
                  <CNEATContextMenus menuid="lvSizeAndFunction" />
                ) : (
                  <ContextMenus menuid="lvSizeAndFunction" />
                )}
              </div>
              <div className="measurementsGroup">
                <div className="measurements">
                  <PrimaryMeasurementsHeader />
                  <MeasurementsContainer>
                    <TextInputWithUOM name="LVIDd" uom={patientData.LVIDdUOM} />
                    <TextInputWithUOM
                      name="IVSd"
                      label="IVS"
                      uom={patientData.IVSdUOM}
                    />
                    <TextInputWithUOM
                      name="LVEDVx"
                      label="LVEDV Idx"
                      uom={patientData.LVEDVxUOM}
                    />
                    <TextInputWithUOM
                      name="COx"
                      label="CO Idx - Simpson's"
                      uom={patientData.COxUOM}
                    />
                    <TextInputWithUOM
                      name="GLS"
                      label="GLS"
                      uom={patientData.GLSUOM}
                    />
                    <TextInputWithUOM name="LVIDs" uom={patientData.LVIDsUOM} />
                    <TextInputWithUOM
                      name="PWd"
                      label="PWd"
                      uom={patientData.PWdUOM}
                    />
                    <TextInputWithUOM
                      name="LVESVx"
                      label="LVESV Idx"
                      uom={patientData.LVESVxUOM}
                    />
                    <TextInputWithUOM
                      name="SVx"
                      label="SV Idx - Simpson's"
                      uom={patientData.SVxUOM}
                    />
                    <TextInputWithUOM
                      name="StrainA4"
                      label="Strain A4"
                      uom={patientData.StrainA4UOM}
                    />
                    <TextInputWithUOM
                      name="EFSimpsons"
                      label="EF (Simpson's)"
                      uom={patientData.EFSimpsonsUOM}
                    />
                    <TextInputWithUOM
                      name="LVMassIndex"
                      label="LV Mass Idx"
                      uom={patientData.LVMassIndexUOM}
                    />
                    <TextInputWithUOM name="LVEDV" uom={patientData.LVEDVUOM} />
                    <TextInputWithUOM
                      name="SV"
                      label="SV - Simpson's"
                      uom={patientData.SVUOM}
                    />
                    <TextInputWithUOM
                      name="StrainA2"
                      label="Strain A2"
                      uom={patientData.StrainA2UOM}
                    />
                    <TextInputWithUOM
                      name="EFTeich"
                      label="EF (Teicholz)"
                      uom={patientData.EFTeichUOM}
                    />
                    <TextInputWithUOM name="RWT" uom={null} />
                    <TextInputWithUOM name="LVESV" uom={patientData.LVESVUOM} />
                    <TextInputWithUOM
                      name="CO"
                      label="CO - Simpson's"
                      uom={patientData.COUOM}
                    />
                    <TextInputWithUOM
                      name="StrainA3"
                      label="Strain A3"
                      uom={patientData.StrainA3UOM}
                    />
                    <TextInputWithUOM
                      name="LVOTDIAM"
                      label="LVOT"
                      uom={patientData.LVOTDIAMUOM}
                    />
                    <TextInputWithUOM
                      name="LVMass"
                      label="LV Mass"
                      uom={patientData.LVMassUOM}
                    />
                  </MeasurementsContainer>
                  <SecondaryMeasurementsContainer>
                    <TextInputWithUOM
                      name="EFA4"
                      label="EF A4"
                      uom={patientData.EFA4UOM}
                    />
                    <TextInputWithUOM
                      name="LVEDVA4x"
                      label="LVEDV Idx A4"
                      uom={patientData.LVEDVA4xUOM}
                    />
                    <TextInputWithUOM
                      name="LVESVA4x"
                      label="LVESV Idx A4"
                      uom={patientData.LVESVA4xUOM}
                    />
                    <TextInputWithUOM
                      name="COA4x"
                      label="CO A4 Idx - Simpson's"
                      uom={patientData.COA4xUOM}
                    />
                    <TextInputWithUOM
                      name="SVA4x"
                      label="SV A4 Idx"
                      uom={patientData.SVA4xUOM}
                    />
                    <TextInputWithUOM
                      name="EFA2"
                      label="EF A2"
                      uom={patientData.EFA2UOM}
                    />
                    <TextInputWithUOM
                      name="LVEDVA2x"
                      label="LVEDV Idx A2"
                      uom={patientData.LVEDVA2xUOM}
                    />
                    <TextInputWithUOM
                      name="LVESVA2x"
                      label="LVESV Idx A2"
                      uom={patientData.LVESVA2xUOM}
                    />
                    <TextInputWithUOM
                      name="COA2x"
                      label="CO A2 Idx - Simpson's"
                      uom={patientData.COA2xUOM}
                    />
                    <TextInputWithUOM
                      name="SVA2x"
                      label="SV A2 Idx"
                      uom={patientData.SVA2xUOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="LVEDVA4"
                      label="LVEDV A4"
                      uom={patientData.LVEDVA4UOM}
                    />
                    <TextInputWithUOM
                      name="LVESVA4"
                      label="LVESV A4"
                      uom={patientData.LVESVA4UOM}
                    />
                    <TextInputWithUOM
                      name="COA4"
                      label="CO A4 - Simpson's"
                      uom={patientData.COA4UOM}
                    />
                    <TextInputWithUOM
                      name="SVA4"
                      label="SV A4"
                      uom={patientData.SVA4UOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="LVEDVA2"
                      label="LVEDV A2"
                      uom={patientData.LVEDVA2UOM}
                    />
                    <TextInputWithUOM
                      name="LVESVA2"
                      label="LVESV A2"
                      uom={patientData.LVESVA2UOM}
                    />
                    <TextInputWithUOM
                      name="COA2"
                      label="CO A2 - Simpson's"
                      uom={patientData.COA2UOM}
                    />
                    <TextInputWithUOM
                      name="SVA2"
                      label="SV A2"
                      uom={patientData.SVA2UOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="LVEDAA4"
                      label="LVED Area A4"
                      uom={patientData.LVEDAA4UOM}
                    />
                    <TextInputWithUOM
                      name="LVESAA4"
                      label="LVES Area A4"
                      uom={patientData.LVESAA4UOM}
                    />
                    <TextInputWithUOM
                      name="LVLdA4"
                      label="LVLd A4"
                      uom={patientData.LVLdA4UOM}
                    />
                    <TextInputWithUOM
                      name="LVLsA4"
                      label="LVLs A4"
                      uom={patientData.LVLsA4UOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="LVEDAA2"
                      label="LVED Area A2"
                      uom={patientData.LVEDAA2UOM}
                    />
                    <TextInputWithUOM
                      name="LVESAA2"
                      label="LVES Area A2"
                      uom={patientData.LVESAA2UOM}
                    />
                    <TextInputWithUOM
                      name="LVLdA2"
                      label="LVLd A2"
                      uom={patientData.LVLdA2UOM}
                    />
                    <TextInputWithUOM
                      name="LVLsA2"
                      label="LVLs A2"
                      uom={patientData.LVLsA2UOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="EDVTeich"
                      label="EDV (Teicholz)"
                      uom={patientData.EDVTeichUOM}
                    />
                    <TextInputWithUOM
                      name="ESVTeich"
                      label="ESV (Teicholz)"
                      uom={patientData.ESVTeichUOM}
                    />
                    <TextInputWithUOM
                      name="COTeichx"
                      label="CO Idx - Teicholz"
                      uom={patientData.COTeichxUOM}
                    />
                    <TextInputWithUOM
                      name="SVTeichx"
                      label="SV Idx - Teicholz"
                      uom={patientData.SVTeichxUOM}
                    />
                    <EmptyGridElement />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="FS"
                      label="FS - Teicholz"
                      uom={patientData.FSUOM}
                    />
                    <TextInputWithUOM
                      name="COTeich"
                      label="CO - Teicholz"
                      uom={patientData.COTeichUOM}
                    />
                    <TextInputWithUOM
                      name="SVTeich"
                      label="SV - Teicholz"
                      uom={patientData.SVTeichUOM}
                    />
                    {/* <TextInputWithUOM
                      name="dummyInitialValue"
                      label="EDV (Cube)"
                      uom={null}
                    />
                    <TextInputWithUOM
                      name="dummyInitialValue"
                      label="ESV (Cube)"
                      uom={null}
                    /> */}
                    {/* <TextInputWithUOM
                      name="dummyInitialValue"
                      label="SV (Cube)"
                      uom={null}
                    />
                    <TextInputWithUOM
                      name="dummyInitialValue"
                      label="SV Idx (Cube)"
                      uom={null}
                    /> */}
                  </SecondaryMeasurementsContainer>
                </div>
              </div>
            </div>
            <div>
              <div onContextMenu={(event) => displayMenu(event, "rvPaPv")}>
                <TextAreaField
                  name="rvPaPv"
                  label={
                    userattr["custom:clinicName"] === "CNEAT"
                      ? "RV size, structure and function"
                      : userattr["custom:clinicName"]?.includes("PACE")
                      ? "Right Ventricle - Size and Function"
                      : "Right Ventricle"
                  }
                  id="rvPaPv"
                  setHovered={setIsHovered}
                />
                {userattr["custom:clinicName"] === "CNEAT" ? (
                  <CNEATContextMenus menuid="rvPaPv" />
                ) : (
                  <ContextMenus menuid="rvPaPv" />
                )}
              </div>
              <div className="measurementsGroup">
                <div className="measurements">
                  <PrimaryMeasurementsHeader />
                  <MeasurementsContainer>
                    <TextInputWithUOM
                      name="RVIDd"
                      label="RVEDd"
                      uom={patientData.RVIDdUOM}
                    />
                    <TextInputWithUOM name="TAPSE" uom={patientData.TAPSEUOM} />
                    <TextInputWithUOM
                      name="S"
                      label="S'"
                      uom={patientData.SUOM}
                    />
                    <TextInputWithUOM name="RVOT" uom={patientData.RVOTUOM} />
                    <EmptyGridElement />
                    {/*                    <TextInputWithUOM
                      name="TRVel"
                      label="TR Vel"
                      uom={patientData.TRVelUOM}
                    />
                    <TextInputWithUOM
                      name="TRPG"
                      label="TR PG"
                      uom={patientData.TRPGUOM}
                    />
                    <TextInputWithUOM
                      name="RASP"
                      label="RAP"
                      uom={patientData.RASPUOM}
                    />
                    <TextInputWithUOM name="RVSP" uom={patientData.RVSPUOM} />
<EmptyGridElement /> */}
                    <TextInputWithUOM
                      name="RVB"
                      label="RV Base"
                      uom={patientData.RVBUOM}
                    />
                    <TextInputWithUOM
                      name="RVM"
                      label="RV Mid"
                      uom={patientData.RVMUOM}
                    />
                    <TextInputWithUOM
                      name="RVL"
                      label="RV Length"
                      uom={patientData.RVLUOM}
                    />
                  </MeasurementsContainer>
                </div>
              </div>
            </div>
            <div>
              <div onContextMenu={(event) => displayMenu(event, "aortaAndAv")}>
                <TextAreaField
                  name="aortaAndAv"
                  label={
                    userattr["custom:clinicName"]?.includes("PACE")
                      ? "Aortic Valve (Aorta) and Pulmonic Valve (P. Artery)"
                      : "Aortic Valve and Pulmonic Valve"
                  }
                  id="aortaAndAv"
                  setHovered={setIsHovered}
                />
                {userattr["custom:clinicName"] === "CNEAT" ? (
                  <CNEATContextMenus menuid="aortaAndAv" />
                ) : (
                  <ContextMenus menuid="aortaAndAv" />
                )}
              </div>
              <div className="measurementsGroup">
                <div className="measurements">
                  <PrimaryMeasurementsHeader />
                  <MeasurementsContainer>
                    <TextInputWithUOM
                      name="AO"
                      label="Ao Diam"
                      uom={patientData.AOUOM}
                    />
                    <TextInputWithUOM
                      name="AscAo"
                      label="Asc Ao"
                      uom={patientData.AscAoUOM}
                    />
                    <TextInputWithUOM
                      name="LAAO"
                      label="LA / AO"
                      uom={patientData.LAAOUOM}
                    />
                    <EmptyGridElement />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="AVVmax"
                      label="AV Vel - V2"
                      uom={patientData.AVVmaxUOM}
                    />
                    <TextInputWithUOM
                      name="LVOTVmax"
                      label="LVOT Vel - V1"
                      uom={patientData.LVOTVmaxUOM}
                    />
                    <TextInputWithUOM name="DI" uom={patientData.DIUOM} />
                    <TextInputWithUOM
                      name="ARPHT"
                      label="AR PHT"
                      uom={patientData.ARPHTUOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="AVPG"
                      label="AV PG - V2"
                      uom={patientData.AVPGUOM}
                    />
                    <TextInputWithUOM
                      name="LVOTmaxPG"
                      label="LVOT PG - V1"
                      uom={patientData.LVOTmaxPGUOM}
                    />
                    <TextInputWithUOM
                      name="AVMG"
                      label="AV MG - V2"
                      uom={patientData.AVMGUOM}
                    />
                    <EmptyGridElement />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="AVAI"
                      label="AVA - Idx - continuity equation"
                      uom={patientData.AVAIUOM}
                    />
                    <TextInputWithUOM
                      name="AVAVmax"
                      label="AVA - continuity equation"
                      uom={patientData.AVAVmaxUOM}
                    />
                    <TextInputWithUOM
                      name="LVOTDIAM"
                      label="LVOT"
                      uom={patientData.LVOTDIAMUOM}
                    />
                    <EmptyGridElement />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="AVAIVTI"
                      label="AVA - Idx - VTI"
                      uom={patientData.AVAIVTIUOM}
                    />
                    <TextInputWithUOM
                      name="AVAVTI"
                      label="AVA - VTI"
                      uom={patientData.AVAVTIUOM}
                    />
                    <TextInputWithUOM
                      name="AVVTI"
                      label="AV VTI - V2"
                      uom={patientData.AVVTIUOM}
                    />
                    <TextInputWithUOM
                      name="LVOTVTI"
                      label="LVOT VTI - V1"
                      uom={patientData.LVOTVTIUOM}
                    />
                    <EmptyGridElement />
                    <TextInputWithUOM
                      name="PVVel"
                      label="PV Vel"
                      uom={patientData.PVVelUOM}
                    />
                    <TextInputWithUOM
                      name="PVPG"
                      label="PV PG"
                      uom={patientData.PVPGUOM}
                    />
                    <TextInputWithUOM
                      name="PVVTI"
                      label="PV VTI"
                      uom={patientData.PVVTIUOM}
                    />
                    <TextInputWithUOM
                      name="QPQS"
                      label="Qp / Qs"
                      uom={patientData.QPQSUOM}
                    />
                  </MeasurementsContainer>
                  <SecondaryMeasurementsContainer>
                    <TextInputWithUOM
                      name="AVSVx"
                      label="AV SV Idx"
                      uom={patientData.AVSVxUOM}
                    />
                    <TextInputWithUOM
                      name="AVCOx"
                      label="AV CO Idx"
                      uom={patientData.AVCOxUOM}
                    />
                    <TextInputWithUOM
                      name="AVSV"
                      label="AV SV"
                      uom={patientData.AVSVUOM}
                    />
                    <TextInputWithUOM
                      name="AVCO"
                      label="AV CO"
                      uom={patientData.AVCOUOM}
                    />
                  </SecondaryMeasurementsContainer>
                </div>
              </div>
            </div>
            {userattr["custom:clinicName"]?.includes("PACE") ? (
              <>
                <Atria />
                <Mitral />
              </>
            ) : (
              <>
                <Mitral />
                <Atria />
              </>
            )}

            <div>
              <div onContextMenu={(event) => displayMenu(event, "vessels")}>
                <TextAreaField
                  name="vessels"
                  label={
                    userattr["custom:clinicName"] === "CNEAT"
                      ? "Vessels, Congenital Heart Disease, Miscellaneous"
                      : userattr["custom:clinicName"]?.includes("PACE")
                      ? "Miscellaneous"
                      : "Vessels and Miscellaneous"
                  }
                  id="vessels"
                  setHovered={setIsHovered}
                />
                {userattr["custom:clinicName"] === "CNEAT" ? (
                  <CNEATContextMenus menuid="vessels" />
                ) : (
                  <ContextMenus menuid="vessels" />
                )}
              </div>
              <div className="measurementsGroup">
                <div className="measurements">
                  {/* <PrimaryMeasurementsHeader /> */}
                  <MeasurementsContainer>
                    {/* <TextInputWithUOM
                    name="MVEA"
                    label="E/A"
                    uom={patientData.MVEAUOM}
                  />
                  <EmptyGridElement />
                  <TextInputWithUOM name="RVSP" uom={patientData.RVSPUOM} /> */}
                  </MeasurementsContainer>
                </div>
              </div>
            </div>

            <Grid
              display={{ base: "none", md: "flex" }}
              templateColumns="95vw"
              rowGap="1rem"
            >
              <Flex width="100%" pb="2rem" justify="space-around">
                <StatusReportLabel
                  htmlFor="zScoreDisplaySwitch"
                  label="Display z-score table?"
                  style={{ paddingBottom: 0 }}
                />
                <Switch
                  width="60vw"
                  colorScheme="blue"
                  size="lg"
                  id="zScoreDisplaySwitch"
                  onChange={setIsZScoreTableDisplayed}
                  isChecked={isZScoreTableDisplayed}
                  disabled={isFormDisabled}
                />
              </Flex>
              {isZScoreTableDisplayed && (
                <ZScoreTable
                  patientData={patientData}
                  zScoreData={zScoreData}
                  setZScoreData={setZScoreData}
                />
              )}
            </Grid>
          </Grid>

          {
            <StorageManager
              acceptedFileTypes={["image/*", ".pdf", ".doc", ".docx"]}
              //             accessLevel="public"
              maxFileCount={1}
              processFile={processFile}
              isResumable
              path={`${patient.SeriesInstanceUID}/`}
              onUploadSuccess={handleUpload}
            />
          }

          <Flex direction="column" justify="flex-end" marginTop="5rem">
            {hasMatchingWord(userattr["custom:clinicName"], "MK") &&
              userattr["custom:role"] !== "external" &&
              userattr["custom:role"] !== "patient" && (
                <SignAndSaveDatePicker />
              )}
            <ReportButtons
              user={userattr}
              setAccessType={setAccessType}
              setStudyStatus={setStudyStatus}
              patientData={patientData}
              // pass shareResult shareEmail prop to render shareAlert as part of Form component
              setShareResult={setShareResult}
              shareResult={shareResult}
              setShareEmail={setShareEmail}
              shareEmail={shareEmail}
              setShareType={setShareType}
              shareType={shareType}
            />
          </Flex>

          <SaveLoader />
        </Form>
      </Formik>
      {formSubmitSuccess ? (
        <AlertMessage status="success" message="Form saved successfully" />
      ) : null}
      {shareResult ? (
        <ShareAlert
          shareEmail={shareEmail}
          shareResult={shareResult}
          shareType={shareType}
        />
      ) : null}
    </>
  );
};

const SaveLoader = () => {
  const { isSubmitting } = useFormikContext();

  if (isSubmitting) {
    return <AlertMessage status="info" message="Saving the form..." />;
  }

  return null;
};

const AlertMessage = ({ status, message, email, type }) => {
  return (
    <Alert status={status} w="25vw" mt="1rem">
      <AlertIcon />
      {message}
      {type
        ? type === "001"
          ? "Physician "
          : type === "000"
          ? "Patient "
          : "Sonographer "
        : null}
      {email}
    </Alert>
  );
};

const ShareStudy = ({
  user,
  setShareResult,
  shareResult,
  setShareEmail,
  setShareType,
  shareType,
  patient,
}) => {
  const emailRef = useRef();

  return (
    <Box>
      <ModalWithGrid
        buttonText="SHARE"
        headingText="Share Study And Report"
        buttonVariant="link"
        buttonColor="#0038ff"
        setShareResult={setShareResult}
        shareResult={shareResult}
        setShareEmail={setShareEmail}
        shareEmail={emailRef}
        setShareType={setShareType}
        shareType={shareType}
        user={user}
        patient={patient}
      >
        <HStack spacing={4}>
          <Input
            placeholder="Enter email address"
            id="shareEmail"
            type="email"
            width="25vw"
            boxSizing="border-box"
            borderRadius="14px"
            border="2px solid #d3d3d3"
            ref={emailRef}
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          />
          <RadioGroup
            id="shareType"
            value={shareType}
            onChange={setShareType}
            _focus={{ outline: "none", border: "2px solid #0038ff" }}
          >
            <VStack align="left">
              <Radio value="000">Patient</Radio>
              <Radio value="001">Physician</Radio>
              <Radio value="002">Sonographer</Radio>
            </VStack>
          </RadioGroup>
        </HStack>
      </ModalWithGrid>
    </Box>
  );
};

const ReportButtons = ({
  user,
  role,
  setAccessType,
  setStudyStatus,
  patientData,
  setShareResult,
  shareResult,
  setShareEmail,
  shareEmail,
  setShareType,
  shareType,
}) => {
  const { dirty, submitForm } = useFormikContext();
  const history = useHistory();
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    },
    { ssr: false }
  );

  const handleClick = (auditAccessType) => {
    if (auditAccessType === "sign") {
      setStudyStatus("Signed");
    } else if (auditAccessType === "archive") {
      setStudyStatus("Archived");
    } else if (auditAccessType === "amend") {
      setStudyStatus("Amended");
    } else if (auditAccessType === "update") {
      user["custom:role"] === "learner"
        ? setStudyStatus("Test In Progress")
        : setStudyStatus("In progress");
    }

    setAccessType(auditAccessType);
    submitForm();

    setTimeout(() => {
      setAccessType("");
      if (auditAccessType === "archive") {
        history.push("/");
      }
    }, 2500);
  };

  const handleClickViewPDF = () => {
    const baseURL = window.location.origin;

    // if (patientData.studyStatus === "Signed") {
    //   window.open(
    //     `${baseURL}/report/${patientData.SeriesInstanceUID}`,
    //     "_blank",
    //     "toolbar=0,location=0,menubar=0"
    //   );
    // } else {
    setAccessType("view");
    //    submitForm();
    setTimeout(() => {
      setAccessType("");
      window.open(
        `${baseURL}/report/${patientData.SeriesInstanceUID}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      );
    }, 2500);
    // }
  };

  return (
    <Grid
      width={patientData.studyStatus === "Signed" ? "70vw" : "60vw"}
      templateColumns={
        isMobile ? null : "200px 200px 200px minmax(200px, auto) 200px 200px"
      }
      columnGap={isMobile ? null : "3vw"}
      rowGap={isMobile ? "3vw" : null}
    >
      <SecondaryButton to="/" as={RouterLink}>
        RETURN TO LIST
      </SecondaryButton>
      {dirty && (
        <PrimaryButton
          type="button"
          onClick={() => {
            patientData.studyStatus === "Signed"
              ? !user["custom:clinicName"]?.includes("MK")
                ? handleClick("amend")
                : handleClick("update")
              : handleClick("update");
          }}
        >
          SAVE
        </PrimaryButton>
      )}
      <SecondaryButton type="button" onClick={handleClickViewPDF}>
        VIEW PDF REPORT
      </SecondaryButton>
      {(hasMatchingWord(user["custom:clinicName"], "MK") ||
        hasMatchingWord(user["custom:clinicName"], "prime") ||
        role?.toLowerCase() === "cardiologist" ||
        role?.toLowerCase() === "educator" ||
        user?.["custom:role"] !== "external" ||
        user?.["custom:role"] !== "patient") && (
        <div>
          <PrimaryButton type="button" onClick={() => handleClick("sign")}>
            SIGN AND SAVE
          </PrimaryButton>
          {patientData.studyStatus === "Signed" && (
            <Text color="red">{`(The study is currently in the signed state. If you make any more changes, please Sign and Save again.)`}</Text>
          )}
        </div>
      )}
      {patientData.studyStatus !== "Signed" &&
        patientData.studyStatus !== "Archived" &&
        user["custom:role"] !== "external" &&
        user["custom:role"] !== "patient" && (
          <SecondaryButton type="button" onClick={() => handleClick("archive")}>
            ARCHIVE
          </SecondaryButton>
        )}
      {patientData.studyStatus === "Signed" && (
        <ShareStudy
          user={user}
          patient={patientData}
          setShareResult={setShareResult}
          shareResult={shareResult}
          setShareEmail={setShareEmail}
          setShareType={setShareType}
          shareType={shareType}
        />
      )}
    </Grid>
  );
};

function DisplayMacro({ macro }) {
  const [isCopied, setIsCopied] = useToggle(false);

  const handleClick = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <Grid gap="1rem" justifyItems="start" templateColumns="1fr 1fr">
      <Text>{macro.measurementName}</Text>
      <Text>
        {macro.macroCode}
        <CopyToClipboard text={macro.macroCode} onCopy={handleClick}>
          <span style={{ cursor: "pointer" }}>📋</span>
        </CopyToClipboard>
      </Text>
      <Text color="blue">{isCopied ? "Copied✅" : ""}</Text>
    </Grid>
  );
}

const ShareAlert = ({ shareEmail, shareResult, shareType }) => {
  return (
    <>
      {shareResult ? (
        <AlertMessage
          status="success"
          message="Sharing invitation email successfully sent to "
          email={shareEmail}
          type={shareType}
        />
      ) : (
        <AlertMessage
          status="failure"
          message="Sharing invitation email not sent to "
          email={shareEmail}
          type={shareType}
        />
      )}
    </>
  );
};

function ModalWithGrid({
  children,
  buttonText,
  headingText,
  setPatientData,
  setShareResult,
  shareResult,
  user,
  patient,
  setShareEmail,
  shareEmail,
  setShareType,
  shareType,
  isTransparent,
  ...props
}) {
  //  const { values, resetForm } = useFormikContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  //  console.log("start modal", shareResult, shareEmail);

  async function handleSave() {
    const updatedData = await updateSeries(
      { ProtocolName: props?.protocolName },
      props?.SeriesInstanceUID
    );
    // console.log('handleSave updatedData: ', updatedData);
    if (updatedData._id) {
      setPatientData(updatedData);
      // resetForm({ values: { ...values, ...updatedData } });
    }
    onClose();
  }

  async function handleShare() {
    /*    console.log(
      "email from modal",
      props,
      user,
      user?.["custom:clinicName"],
      patient,
      "shareResult",
      shareResult,
      "shareEmail.current.value",
      shareEmail.current.value,
      "shareType",
      shareType
    );
*/
    // couldn't use .value property in setShareEmail, so set variable
    const sharedEmail = shareEmail.current.value;
    const frontend = process.env.REACT_APP_PRODUCTION;

    if (
      !sharedEmail ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(sharedEmail)
    ) {
      setShareResult(false);
      return;
    }

    const data = await sendShareEmail(
      shareEmail.current.value,
      patient.SeriesInstanceUID,
      user.email,
      user?.["custom:clinicName"],
      patient.PatientID,
      shareType,
      frontend
    );

    // display shareAlert and shareEmail, shareType already set by radio button
    if (data.message === "Mail sent") {
      setTimeout(() => {
        setShareResult(true);
        setShareEmail(sharedEmail);
      }, 500);

      //  don't call components from event handler functions; use state
      //  ShareAlert(shareEmail.current.value, shareResult);

      addAuditData(
        user,
        patient,
        `Shared with ${
          shareType === "001"
            ? "physician"
            : shareType === "000"
            ? "patient"
            : "sonographer"
        } 
          ${shareEmail.current.value}`
      );
    }

    // setShareEmail("");

    //  displays shareAlert for 5 seconds
    setTimeout(() => {
      setShareResult(false);
      setShareType(null);
    }, 4000);

    // second ShareAlert is not needed - alert automatically disappears after shareResult set to false
    // ShareAlert(data, props?.shareEmail, shareResult);

    onClose();
  }

  return (
    <>
      <SecondaryButton
        display={{ base: "none", md: "flex" }}
        onClick={onOpen}
        color={props?.buttonColor}
        variant={props?.buttonVariant}
        style={{
          backgroundColor: isTransparent
            ? "rgba(0, 0, 0, 0.05)"
            : "rgba(49, 130, 206)",
          color: isTransparent ? "rgba(0, 0, 0, 0.05)" : "whitesmoke",
        }}
      >
        {buttonText}
      </SecondaryButton>

      <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headingText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(2, 1fr)" gap="2rem 3rem">
              {children}
            </Grid>
          </ModalBody>

          <ModalFooter>
            {buttonText?.toLowerCase().includes("study type") ? (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleSave}>
                  Save
                </Button>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={onClose}
                  variant="outline"
                >
                  Cancel
                </Button>
              </>
            ) : buttonText?.toLowerCase().includes("share") ? (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleShare}>
                  Share
                </Button>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={onClose}
                  variant="outline"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const zScoreTableMeasurements = [
  // measurement property matches the measurement value API field
  { label: "RVED", measurement: "RVIDd" },
  { label: "IVS", measurement: "IVSd" },
  { label: "LVED", measurement: "LVIDd" },
  { label: "LVPW", measurement: "PWd" },
  { label: "LA", measurement: "LAd" },
  { label: "Aortic Valve", measurement: "AO" },
  { label: "MPA", measurement: "pMPA" },
  { label: "Pulmonary Valve", measurement: "pPV" },
  { label: "Mitral Valve", measurement: "pMV" },
  { label: "Tricuspid Valve", measurement: "pTV" },
  { label: "RPA prox", measurement: "pRPA" },
  { label: "LPA prox", measurement: "pLPA" },
  { label: "Aortic Sinus", measurement: "pAS" },
  { label: "Sinotubular Junction", measurement: "pSJ" },
  { label: "Ascending Aorta", measurement: "AscAo" },
  { label: "Transverse Arch", measurement: "pTRAR" },
  { label: "Isthmus", measurement: "pIST" },
  { label: "Descending Aorta", measurement: "pDscAo" },
];

const ZScoreTable = ({ patientData, zScoreData, setZScoreData }) => {
  const handleChange = (event, name) => {
    setZScoreData((prevState) => ({
      ...prevState,
      [name]: event?.target?.value,
    }));
  };

  const MeasurementInputWithUOM = ({ ...props }) => {
    const [field] = useField(props);

    return (
      <InputGroup w="8rem">
        <Input {...field} {...props} />
        {patientData[props.name + "UOM"] ? (
          <InputRightAddon children={patientData[props.name + "UOM"]} />
        ) : (
          ""
        )}
      </InputGroup>
    );
  };

  return (
    <>
      <Grid gridTemplateColumns="repeat(2, 1fr)" width="90vw">
        <Grid gridTemplateColumns="40% 30% 30%" border="1px solid black">
          <Text></Text>
          <Text fontSize="lg">Measurement</Text>
          <Text fontSize="lg">Z-score</Text>
        </Grid>
        <Grid gridTemplateColumns="40% 30% 30%" border="1px solid black">
          <Text></Text>
          <Text fontSize="lg">Measurement</Text>
          <Text fontSize="lg">Z-score</Text>
        </Grid>
      </Grid>
      <Grid
        gridAutoFlow="column"
        gridTemplateRows="repeat(10, auto)"
        width="90vw"
      >
        {zScoreTableMeasurements.map((elem) => (
          <Grid
            gridTemplateColumns="40% 30% 30%"
            columnGap="1rem"
            border="1px solid black"
            padding=".5rem"
            alignItems="baseline"
            key={elem.measurement}
          >
            <Text>{elem.label}</Text>
            <MeasurementInputWithUOM name={elem.measurement} />
            <Input
              w="5rem"
              borderColor="#E2E8F0"
              value={zScoreData?.[elem.measurement]}
              onChange={(event) => handleChange(event, elem.measurement)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

// measurement property matches the measurement value API field
const basalSegments = [
  { label: "1. Basal anterior", measurement: "basalAnterior" },
  { label: "2. Basal anteroseptal", measurement: "basalAnteroseptal" },
  { label: "3. Basal inferoseptal", measurement: "basalInferoseptal" },
  { label: "4. Basal inferior", measurement: "basalInferior" },
  { label: "5. Basal inferolateral", measurement: "basalInferolateral" },
  { label: "6. Basal anterolateral", measurement: "basalAnterolateral" },
];

const midCavitySegments = [
  { label: "7. Mid anterior", measurement: "midAnterior" },
  { label: "8. Mid anteroseptal", measurement: "midAnteroseptal" },
  { label: "9. Mid inferoseptal", measurement: "midInferoseptal" },
  { label: "10. Mid inferior", measurement: "midInferior" },
  { label: "11. Mid inferolateral", measurement: "midInferolateral" },
  { label: "12. Mid anterolateral", measurement: "midAnterolateral" },
];

const apicalSegments = [
  { label: "13. Apical anterior", measurement: "apicalAnterior" },
  { label: "14. Apical septal", measurement: "apicalSeptal" },
  { label: "15. Apical inferior", measurement: "apicalInferior" },
  { label: "16. Apical lateral", measurement: "apicalLateral" },
  { label: "17. Apex", measurement: "apex" },
  { label: "WMSI", measurement: "wmsi" },
];

// POST-EXERCISE measurement property matches the measurement value API field
const postbasalSegments = [
  { label: "1. Basal anterior", measurement: "postbasalAnterior" },
  { label: "2. Basal anteroseptal", measurement: "postbasalAnteroseptal" },
  { label: "3. Basal inferoseptal", measurement: "postbasalInferoseptal" },
  { label: "4. Basal inferior", measurement: "postbasalInferior" },
  { label: "5. Basal inferolateral", measurement: "postbasalInferolateral" },
  { label: "6. Basal anterolateral", measurement: "postbasalAnterolateral" },
];

const postmidCavitySegments = [
  { label: "7. Mid anterior", measurement: "postmidAnterior" },
  { label: "8. Mid anteroseptal", measurement: "postmidAnteroseptal" },
  { label: "9. Mid inferoseptal", measurement: "postmidInferoseptal" },
  { label: "10. Mid inferior", measurement: "postmidInferior" },
  { label: "11. Mid inferolateral", measurement: "postmidInferolateral" },
  { label: "12. Mid anterolateral", measurement: "postmidAnterolateral" },
];

const postapicalSegments = [
  { label: "13. Apical anterior", measurement: "postapicalAnterior" },
  { label: "14. Apical septal", measurement: "postapicalSeptal" },
  { label: "15. Apical inferior", measurement: "postapicalInferior" },
  { label: "16. Apical lateral", measurement: "postapicalLateral" },
  { label: "17. Apex", measurement: "postapex" },
  { label: "WMSI", measurement: "postwmsi" },
];

const BullsEyeTable = ({ setBullsEyeData, patientData }) => {
  const handleChange = (event, name) => {
    setBullsEyeData((prevState) => ({
      ...prevState,
      [name]: event?.target?.value,
    }));
  };

  const protocolName = patientData.ProtocolName?.toLowerCase();
  const isStressEcho = protocolName?.includes("stress")
    ? true
    : protocolName?.includes("dobutamine")
    ? true
    : false;

  const MeasurementInput = ({ ...props }) => {
    const [field] = useField(props);

    return <Input {...field} {...props} />;
  };

  return (
    <>
      <Grid
        templateColumns="1fr 1fr"
        paddingLeft="2pt"
        fontFamily="Jost"
        fontSize="18px"
      >
        <Grid alignItems="center">
          <img src={imgBullseye} alt="Bullseye" />
        </Grid>

        <Grid>
          <Grid templateColumns="repeat(3, 1fr)" fontWeight="500">
            <Grid border="1px solid black" alignItems="center">
              <Text paddingLeft="6pt">Basal segments Resting</Text>
            </Grid>
            <Grid border="1px solid black" alignItems="center">
              <Text paddingLeft="6pt">Mid-Cavity segments Resting</Text>
            </Grid>
            <Grid border="1px solid black" alignItems="center">
              <Text paddingLeft="6pt">Apical segments Resting</Text>
            </Grid>
          </Grid>
          <Grid
            gridAutoFlow="column"
            gridTemplateRows="repeat(6, auto)"
            width="60vw"
          >
            {basalSegments.map((elem) => (
              <Grid
                gridTemplateColumns="13vw 5vw"
                border="1px solid black"
                padding=".5rem"
                alignItems="baseline"
                key={elem.measurement}
              >
                <Text>{elem.label}</Text>
                <MeasurementInput name={elem.measurement} />
              </Grid>
            ))}
            {midCavitySegments.map((elem) => (
              <Grid
                gridTemplateColumns="13vw 5vw"
                border="1px solid black"
                padding=".5rem"
                alignItems="baseline"
                key={elem.measurement}
              >
                <Text>{elem.label}</Text>
                <MeasurementInput name={elem.measurement} />
              </Grid>
            ))}
            {apicalSegments.map((elem) => (
              <Grid
                gridTemplateColumns="13vw 5vw"
                border="1px solid black"
                padding=".5rem"
                alignItems="baseline"
                key={elem.measurement}
              >
                <Text>{elem.label}</Text>
                <MeasurementInput name={elem.measurement} />
              </Grid>
            ))}
          </Grid>

          {isStressEcho && (
            <Grid marginTop="10pt">
              <Grid templateColumns="repeat(3, 1fr)" fontWeight="500">
                <Grid border="1px solid black" alignItems="center">
                  <Text paddingLeft="6pt">Basal segments Post-Exercise</Text>
                </Grid>
                <Grid border="1px solid black" alignItems="center">
                  <Text paddingLeft="6pt">
                    Mid-Cavity segments Post-Exercise
                  </Text>
                </Grid>
                <Grid border="1px solid black" alignItems="center">
                  <Text paddingLeft="6pt">Apical segments Post-Exercise</Text>
                </Grid>
              </Grid>
              <Grid
                gridAutoFlow="column"
                gridTemplateRows="repeat(6, auto)"
                width="60vw"
              >
                {postbasalSegments.map((elem) => (
                  <Grid
                    gridTemplateColumns="13vw 5vw"
                    border="1px solid black"
                    padding=".5rem"
                    alignItems="baseline"
                    key={elem.measurement}
                  >
                    <Text>{elem.label}</Text>
                    <MeasurementInput name={elem.measurement} />
                  </Grid>
                ))}
                {postmidCavitySegments.map((elem) => (
                  <Grid
                    gridTemplateColumns="13vw 5vw"
                    border="1px solid black"
                    padding=".5rem"
                    alignItems="baseline"
                    key={elem.measurement}
                  >
                    <Text>{elem.label}</Text>
                    <MeasurementInput name={elem.measurement} />
                  </Grid>
                ))}
                {postapicalSegments.map((elem) => (
                  <Grid
                    gridTemplateColumns="13vw 5vw"
                    border="1px solid black"
                    padding=".5rem"
                    alignItems="baseline"
                    key={elem.measurement}
                  >
                    <Text>{elem.label}</Text>
                    <MeasurementInput name={elem.measurement} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default StatusReportForm;
