import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";

// Create a UserContext
const UserContext = createContext(null);

// Provider component that wraps the app
export const UserProvider = ({ children, user }) => {
  const [userState, setUserState] = useState({
    attributes: null,
    session: null,
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const session = await fetchAuthSession();
        const attribs = await fetchUserAttributes();
        setUserState({
          attributes: attribs,
          session,
        });
      } catch (err) {
        console.error("Error fetching user:", err);
        setUserState({
          attributes: null,
          session: null,
        });
      }
    };

    fetchUserDetails();
  }, [user]);

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
};

// Hook to access the user context easily
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
