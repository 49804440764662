import { useFormikContext } from "formik";
import { Menu, Item, Separator, Submenu } from "react-contexify";
import "react-contexify/ReactContexify.css";

export default function ContextMenus(menuid) {
  const { setFieldValue, values } = useFormikContext();

  //  console.log("menuid", menuid.menuid);

  function HandleItemClick({ event, triggerEvent, data }) {
    let key = triggerEvent.srcElement.id;
    let testConclusion = document.getElementById("testConclusion").id;

    if (event.detail === 1) {
      if (values[key]) {
        setFieldValue(key, values[key] + ". " + data);
      } else {
        setFieldValue(key, data);
      }
    }

    if (event.detail === 2) {
      if (values[testConclusion]) {
        setFieldValue(testConclusion, values[testConclusion] + ". " + data);
      } else {
        setFieldValue(testConclusion, data);
      }
    }
  }

  switch (menuid.menuid) {
    case "lvSizeAndFunction":
      return (
        <Menu id="lvSizeAndFunction">
          <Submenu label="Left Ventricle">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV in size and function with EF > 55% and no regional WMA.
                    Normal LV wall thickness. Normal Diastolic Function."
            >
              Normal LV
            </Item>
            <Submenu label="Cavity shape and size">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal cavity shape and size"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Upper limit of normal cavity shape and size"
              >
                Upper limit of normal
              </Item>
              <Separator />
              <Submenu label="Dilation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV is mildly dilated"
                >
                  Mildly dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV is moderately dilated"
                >
                  Moderately dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV is severely dilated"
                >
                  Severely dilated
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Volume">
                <Submenu label="Systolic">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV systolic volume is mildly increased"
                  >
                    Mildly increased
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV systolic volume is moderately increased"
                  >
                    Moderately increased
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV systolic volume is severely increased"
                  >
                    Severely increased
                  </Item>
                </Submenu>
                <Submenu label="Diastolic">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV diastolic volume is mildly increased"
                  >
                    Mildly increased
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV diastolic volume is moderately increased"
                  >
                    Moderately increased
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="LV diastolic volume is severely increased"
                  >
                    Severely increased
                  </Item>
                </Submenu>
              </Submenu>
              <Separator />
              <Submenu label="Aneurysmal">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV displays an apical aneurysm with no obvious clot visualized"
                >
                  Apical aneurysm, no obvious clot visualized
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV displays an apical aneurysm, clot has been visualized"
                >
                  Apical aneurysm with clot
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Thrombus">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Visible layered thrombus visualized in the LV"
                >
                  Layered thrombus visualized
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="An LV thrombus cannot be ruled out"
                >
                  Thrombus cannot be ruled out
                </Item>
              </Submenu>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV anatomy and findings are consistent with Takotsubo"
              >
                Takotsubo
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="A false chord has been noted in the LV"
              >
                False chord noted
              </Item>
            </Submenu>
            <Submenu label="Wall thickness">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LV wall thickness when indexed for BSA"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV walls measure at upper limits of normal"
              >
                Upper limit of normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="There is evidence of sigmoid septum"
              >
                Sigmoid septum
              </Item>
              <Separator />
              <Submenu label="Asymmetric septal hypertrophy">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display mild asymmetric septal hypertrophy"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display moderate asymmetric septal hypertrophy"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display severe asymmetric septal hypertrophy"
                >
                  Severe
                </Item>
              </Submenu>
              <Separator />
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV wall measurements display concentric remodeling"
              >
                Concentric remodeling
              </Item>
              <Separator />
              <Submenu label="Concentric LVH">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display mild concentric LVH"
                >
                  Mild LVH
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display moderate concentric LVH"
                >
                  Moderate LVH
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display severe concentric LVH"
                >
                  Severe LVH
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Eccentric LVH">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display mild eccentric LVH"
                >
                  Mild LVH
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display moderate eccentric LVH"
                >
                  Moderate LVH
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV wall measurements display severe eccentric LVH"
                >
                  Severe LVH
                </Item>
              </Submenu>
              <Separator />
            </Submenu>
            <Submenu label="LVF">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LV function with EF > 55% and no regional WMA"
              >
                Normal LV systolic function
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Grossly normal LVF"
              >
                Grossly normal LVF
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="LV WMA cannot be ruled out"
              >
                Cannot r/o WMA
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly reduced LV systolic function"
              >
                Mildly reduced LV systolic function
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately reduced LV systolic function"
              >
                Moderately reduced LV systolic function
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely reduced LV systolic function"
              >
                Severely reduced LV systolic function
              </Item>
              <Separator />
              <Submenu label="Could not accurately measure">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV systolic function measurements cannot be measured. Normal systolic function by visual estimation"
                >
                  Normal systolic function by visual estimation
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV systolic function measurements cannot be measured. Decreased systolic function by visual estimation"
                >
                  Decreased systolic function by visual estimation
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Grade">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Grade I LV function"
                >
                  Grade I
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Grade II LV function"
                >
                  Grade II
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Grade III LV function"
                >
                  Grade III
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Grade IV LV function"
                >
                  Grade IV
                </Item>
              </Submenu>
              <Submenu label="RWMA">
                <Submenu label="Septal">
                  <Submenu label="Antero-Septal">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Septal Mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="Infero-Septal">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Septal Mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Infero-Septal apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Infero-Septal apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Infero-Septal apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Inferior">
                  <Submenu label="Inferior">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA inferior mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA inferior apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA inferior apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA inferior apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Lateral">
                  <Submenu label="Infero-Lateral">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Infero-Lateral mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="Antero-Lateral">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="RWMA Antero-Lateral mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Antero-Lateral apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Antero-Lateral apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="RWMA Antero-Lateral apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Apex">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="RWMA Apex Hypo"
                  >
                    Hypo
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="RWMA Apex Akin"
                  >
                    Akin
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="RWMA Apex Dysk"
                  >
                    Dysk
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Global hypokinesis">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild global hypokinesis"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate global hypokinesis"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe global hypokinesis"
                >
                  Severe
                </Item>
              </Submenu>
              <Separator />
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Global akinesis"
              >
                Global akinesis
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Hyperdynamic"
              >
                Hyperdynamic
              </Item>
              <Separator />
              <Submenu label="Global hyperkinesis with regional variability">
                <Submenu label="Septal">
                  <Submenu label="Antero-Septal">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Septal mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="Infero-Septal">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Septal mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Inferior">
                  <Submenu label="Inferior">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis inferior mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Lateral">
                  <Submenu label="Infero-Lateral">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Infero-Lateral mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="Antero-Lateral">
                    <Submenu label="Basal">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral basal Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral basal Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral basal Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                    <Submenu label="Mid">
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral mid Hypo"
                      >
                        Hypo
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral mid Akin"
                      >
                        Akin
                      </Item>
                      <Item
                        closeOnClick={false}
                        onClick={HandleItemClick}
                        data="Global hyperkinesis Antero-Lateral mid Dysk"
                      >
                        Dysk
                      </Item>
                    </Submenu>
                  </Submenu>
                  <Submenu label="apical">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Hypo"
                    >
                      Hypo
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Akin"
                    >
                      Akin
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Global hyperkinesis apical Dysk"
                    >
                      Dysk
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Apex">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Global hyperkinesis Apex Hypo"
                  >
                    Hypo
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Global hyperkinesis Apex Akin"
                  >
                    Akin
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Global hyperkinesis Apex Dysk"
                  >
                    Dysk
                  </Item>
                </Submenu>
              </Submenu>
            </Submenu>
            <Submenu label="Diastolic Function">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal diastolic function"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Indeterminate diastolic function"
              >
                Indeterminate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Diastolic function cannot be determined"
              >
                Cannot be determined
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Grade I: Normal LAP"
              >
                Grade I: Normal LAP
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Grade II: Increased LAP"
              >
                Grade II: Increased LAP
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Grade III: Increased LAP"
              >
                Grade III: Increased LAP
              </Item>
            </Submenu>
            <Submenu label="SAM">
              <Submenu label="Chordal SAM">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Chordal SAM no significant PG found"
                >
                  No significant PG found
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Chordal SAM PG increase, no changes with valsalva"
                >
                  PG increase, no changes with valsalva
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Chordal SAM PG increase, further increase with valsalva"
                >
                  PG increase, further increase with valsalva
                </Item>
              </Submenu>
              <Submenu label="AMVL SAM">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="AMVL SAM no significant PG found"
                >
                  No significant PG found
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="AMVL SAM PG increase, no changes with valsalva"
                >
                  PG increase, no changes with valsalva
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="AMVL SAM PG increase, further increase with valsalva"
                >
                  PG increase, further increase with valsalva
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
          <Submenu label="IVS">
            <Submenu label="Structure">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal IVS"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Sigmoid septum IVS"
              >
                Sigmoid septum
              </Item>
              <Submenu label="VSD">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Perimembranous VSD"
                >
                  Perimembranous
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Muscular VSD"
                >
                  Muscular
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Serpentuous VSD"
                >
                  Serpentuous
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Conoventricular VSD"
                >
                  Conoventricular
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Inlet VSD"
                >
                  Inlet
                </Item>
              </Submenu>
              <Submenu label="IVS repair noted">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="IVS repair noted no flow remains"
                >
                  No flow remains
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="IVS repair noted colour doppler evidence of shunting"
                >
                  Colour doppler evidence of shunting
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Paradoxical Septal motion">
              <Submenu label="Septal bounce">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical septal motion bounce BBB"
                >
                  BBB
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical septal motion bounce postsurgical"
                >
                  Postsurgical
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical septal motion bounce pacemaker"
                >
                  Pacemaker
                </Item>
              </Submenu>
              <Submenu label="D Shape IVS">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical septal motion D Shape IVS RVVO"
                >
                  RVVO
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical septal motion D Shape IVS RVPO"
                >
                  RVPO
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
        </Menu>
      );

    case "aortaAndAv":
      return (
        <Menu id="aortaAndAv">
          <Submenu label="Aortic Valve">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Aortic Valve normal"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Aortic Valve not well visualized"
            >
              Not well visualized
            </Item>
            <Submenu label="Structure">
              <Submenu label="Thickness">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Aortic Valve sclerotic"
                >
                  Sclerotic
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Aortic Valve calcified"
                >
                  Calcified
                </Item>
              </Submenu>
              <Submenu label="Cusps">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Aortic Valve tricuspid"
                >
                  Tricuspid
                </Item>
                <Submenu label="Bicuspid">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Aortic Valve bicuspid orientation at 2 and 8 o'clock"
                  >
                    Orientation at 2 and 8 o'clock
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Aortic Valve bicuspid orientation at 3 and 9 o'clock"
                  >
                    Orientation at 3 and 9 o'clock
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Aortic Valve bicuspid orientation at 5 and 11 o'clock"
                  >
                    Orientation at 5 and 11 o'clock
                  </Item>
                </Submenu>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Aortic Valve quadricuspid"
                >
                  Quadricuspid
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Aortic Valve unicuspid"
                >
                  Unicuspid
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Prosthetic">
                <Submenu label="Biological Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Well seated biological aortic valve prosthetic"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant dysfunction biological aortic valve prosethetic"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic dysfunction biological aortic valve prosethetic"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation dysfunction biological aortic valve prosethetic"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Mechanical Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Well seated mechanical aortic valve prosthetic"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant dysfunction mechanical aortic valve prosethetic"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic dysfunction mechanical aortic valve prosethetic"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation dysfunction mechanical aortic valve prosethetic"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Separator />
              </Submenu>
              <Submenu label="TAVI">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Well seated TAVI"
                >
                  Well seated
                </Item>
                <Submenu label="Regurgitation">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Trivial regurgitation aortic valve TAVI"
                  >
                    Trivial
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Trace regurgitation aortic valve TAVI"
                  >
                    Trace
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Trace-Mild regurgitation aortic valve TAVI"
                  >
                    Trace-Mild
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Mild regurgitation aortic valve TAVI"
                  >
                    Mild
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Mild-Moderate regurgitation aortic valve TAVI"
                  >
                    Mild-Moderate
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Moderate regurgitation aortic valve TAVI"
                  >
                    Moderate
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Moderate-Severe regurgitation aortic valve TAVI"
                  >
                    Moderate-Severe
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Severe regurgitation aortic valve TAVI"
                  >
                    Severe
                  </Item>
                  <Submenu label="Jet direction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Anterior jet direction regurgitation aortic valve TAVI"
                    >
                      Anterior
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Posterior jet direction regurgitation aortic valve TAVI"
                    >
                      Posterior
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Eccentric jet direction regurgitation aortic valve TAVI"
                    >
                      Eccentric
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Stenosis">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Mild stenosis aortic valve TAVI"
                  >
                    Mild
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Mild-Moderate stenosis aortic valve TAVI"
                  >
                    Mild-Moderate
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Moderate stenosis aortic valve TAVI"
                  >
                    Moderate
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Moderate-Severe stenosis aortic valve TAVI"
                  >
                    Moderate-Severe
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="True severe stenosis aortic valve TAVI"
                  >
                    True Severe
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Low-Flow Low-Gradient with preserved EF stenosis aortic valve TAVI"
                  >
                    Low-Flow Low-Gradient with preserved EF
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Low-Flow Low-Gradient with reduced EF stenosis aortic valve TAVI"
                  >
                    Low-Flow Low-Gradient with reduced EF
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Paradoxical Low-Flow Low-Gradient with preserved EF stenosis aortic valve TAVI"
                  >
                    Paradoxical Low-Flow Low-Gradient with preserved EF
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Critical stenosis aortic valve TAVI"
                  >
                    Critical
                  </Item>
                </Submenu>
              </Submenu>
            </Submenu>
            <Submenu label="Function">
              <Submenu label="Regurgitation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trivial regurgitation aortic valve"
                >
                  Trivial
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace regurgitation aortic valve"
                >
                  Trace
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace-Mild regurgitation aortic valve"
                >
                  Trace-Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild regurgitation aortic valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate regurgitation aortic valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate regurgitation aortic valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe regurgitation aortic valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe regurgitation aortic valve"
                >
                  Severe
                </Item>
                <Submenu label="Jet direction">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Anterior jet direction regurgitation aortic valve"
                  >
                    Anterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Posterior jet direction regurgitation aortic valve"
                  >
                    Posterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Eccentric jet direction regurgitation aortic valve"
                  >
                    Eccentric
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Stenosis">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild stenosis aortic valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate stenosis aortic valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate stenosis aortic valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe stenosis aortic valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="True severe stenosis aortic valve"
                >
                  True Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Low-Flow Low-Gradient with preserved EF stenosis aortic valve"
                >
                  Low-Flow Low-Gradient with preserved EF
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Low-Flow Low-Gradient with reduced EF stenosis aortic valve"
                >
                  Low-Flow Low-Gradient with reduced EF
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Paradoxical Low-Flow Low-Gradient with preserved EF stenosis aortic valve"
                >
                  Paradoxical Low-Flow Low-Gradient with preserved EF
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Critical stenosis aortic valve"
                >
                  Critical
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
          <Submenu label="Pulmonary Valve">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pulmonary Valve normal"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pulmonary Valve not well visualized"
            >
              Not well visualized
            </Item>
            <Submenu label="Structure">
              <Submenu label="Thickness">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Pulmonary Valve sclerotic"
                >
                  Sclerotic
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Pulmonary Valve calcified"
                >
                  Calcified
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Prosthetic">
                <Submenu label="Biological Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Well seated biological pulmonary valve prosthetic"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant dysfunction biological pulmonary valve prosethetic"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic dysfunction biological pulmonary valve prosethetic"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation dysfunction biological pulmonary valve prosethetic"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Mechanical Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Well seated mechanical pulmonary valve prosthetic"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant dysfunction mechanical pulmonary valve prosethetic"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic dysfunction mechanical pulmonary valve prosethetic"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation dysfunction mechanical pulmonary valve prosethetic"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Separator />
              </Submenu>
            </Submenu>
            <Submenu label="Function">
              <Submenu label="Regurgitation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trivial regurgitation pulmonary valve"
                >
                  Trivial
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace regurgitation pulmonary valve"
                >
                  Trace
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace-Mild regurgitation pulmonary valve"
                >
                  Trace-Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild regurgitation pulmonary valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate regurgitation pulmonary valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate regurgitation pulmonary valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe regurgitation pulmonary valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe regurgitation pulmonary valve"
                >
                  Severe
                </Item>
                <Submenu label="Jet direction">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Anterior jet direction regurgitation pulmonary valve"
                  >
                    Anterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Posterior jet direction regurgitation pulmonary valve"
                  >
                    Posterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Eccentric jet direction regurgitation pulmonary valve"
                  >
                    Eccentric
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Stenosis">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild stenosis pulmonary valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate stenosis pulmonary valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate stenosis pulmonary valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe stenosis pulmonary valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe stenosis pulmonary valve"
                >
                  Severe
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
        </Menu>
      );

    case "rvPaPv":
      return (
        <Menu id="rvPaPv">
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Normal right ventricular size and function"
          >
            Normal
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Not well visualized"
          >
            Not well visualized
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Upper limit of normal"
          >
            Upper limit of normal
          </Item>
          <Submenu label="Chamber Size">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal chamber size"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mildly dilated chamber size"
            >
              Mildly dilated
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderately dilated chamber size"
            >
              Moderately dilated
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Severely dilated chamber size"
            >
              Severely dilated
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="RVVO">
              RVVO
            </Item>
            <Item closeOnClick={false} onClick={HandleItemClick} data="RVPO">
              RVPO
            </Item>
          </Submenu>
          <Submenu label="Wall Thickness">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal wall thickness"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hypertrophy"
            >
              Hypertrophy
            </Item>
          </Submenu>
          <Submenu label="Systolic Function">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal systolic function"
            >
              Normal
            </Item>
            <Submenu label="TAPSE">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal TAPSE"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Decreased TAPSE"
              >
                Decreased
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mildly reduced systolic function"
            >
              Mildly reduced
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderately reduced systolic function"
            >
              Moderately reduced
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Severely reduced systolic function"
            >
              Severely reduced
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Systolic function decreased by visual estimation"
            >
              Decreased by visual estimation
            </Item>
          </Submenu>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Collapse consistent with Tamponade"
          >
            Collapse consistent with Tamponade
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Wire visualized"
          >
            Wire visualized
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="Pacemaker wire visualized"
          >
            Pacemaker wire visualized
          </Item>
          <Item
            closeOnClick={false}
            onClick={HandleItemClick}
            data="ICD wire visualized"
          >
            ICD wire visualized
          </Item>
        </Menu>
      );

    case "mvAndTv":
      return (
        <Menu id="mvAndTv">
          <Submenu label="Mitral Valve">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal MV"
            >
              Normal MV
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="MV not well seen"
            >
              Not well seen
            </Item>
            <Submenu label="Structure">
              <Submenu label="MAC Thick">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild MAC Thick MV"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate MAC Thick MV"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe MAC Thick MV"
                >
                  Severe
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Thickened">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mildly thickened mitral leaflets"
                >
                  Mildly thickened mitral leaflets
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderately thickened mitral leaflets"
                >
                  Moderately thickened mitral leaflets
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severely thickened mitral leaflets"
                >
                  Severely thickened mitral leaflets
                </Item>
              </Submenu>
              <Separator />
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Myxomatous MV"
              >
                Myxomatous
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Rheumatic MV"
              >
                Rheumatic
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="MV Doming"
              >
                Doming
              </Item>
              <Submenu label="MVP">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Borderline MVP"
                >
                  Borderline
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Anterior Leaflet MVP"
                >
                  Anterior Leaflet
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Posterior Leaflet MVP"
                >
                  Posterior Leaflet
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Bileaflet MVP"
                >
                  Bileaflet
                </Item>
              </Submenu>
              <Submenu label="Flail">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Anterior Leaflet Flail"
                >
                  Anterior Leaflet
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Posterior Leaflet Flail"
                >
                  Posterior Leaflet
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Bileaflet Flail"
                >
                  Bileaflet
                </Item>
              </Submenu>
              <Submenu label="Prosthetic">
                <Submenu label="Biological Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Prosthetic biological valve well seated"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant prosthetic biological valve"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic prosthetic biological valve"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation prosthetic biological valve"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Mechanical Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Prosthetic mechanical valve well seated"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant prosthetic mechanical valve"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic prosthetic mechanical valve"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation prosthetic mechanical valve"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
              </Submenu>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Evidence of prior mitral valve repair"
              >
                Evidence of prior mitral valve repair
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Evidence of mitral annular ring"
              >
                Evidence of mitral annular ring
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Evidence of mitral clip procedure"
              >
                Evidence of mitral clip procedure
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Apically tented mitral leaflets due to LV systolic dysfunction"
              >
                Apically tented mitral leaflets due to LV systolic dysfunction
              </Item>
            </Submenu>
            <Submenu label="Function">
              <Submenu label="Regurgitation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trivial regurgitation mitral valve"
                >
                  Trivial
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace regurgitation mitral valve"
                >
                  Trace
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild regurgitation mitral valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate regurgitation mitral valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate regurgitation mitral valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe regurgitation mitral valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe regurgitation mitral valve"
                >
                  Severe
                </Item>
                <Submenu label="Jet direction">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Anterior jet direction regurgitation mitral valve"
                  >
                    Anterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Posterior jet direction regurgitation mitral valve"
                  >
                    Posterior
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Eccentric jet direction regurgitation mitral valve"
                  >
                    Eccentric
                  </Item>
                </Submenu>
              </Submenu>
              <Submenu label="Stenosis">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild stenosis mitral valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate stenosis mitral valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate stenosis mitral valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe stenosis mitral valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe stenosis mitral valve"
                >
                  Severe
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Filling patterns">
              <Submenu label="Tamponade">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data=">30% inspiration variability in MV inflow"
                >
                  &>30% inspiration variability in MV inflow
                </Item>
              </Submenu>
            </Submenu>
          </Submenu>
          <Submenu label="Tricuspid Valve">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal TV"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="TV not well seen"
            >
              Not well seen
            </Item>
            <Submenu label="Structure">
              <Submenu label="Thickened">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mildly thickened TV"
                >
                  Mildly
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderately thickened TV"
                >
                  Moderately
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severely thickened TV"
                >
                  Severely
                </Item>
              </Submenu>
              <Separator />
              <Submenu label="Prosthetic">
                <Submenu label="Biological Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Prosthetic biological TV well seated"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant prosthetic biological TV"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic prosthetic biological TV"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation prosthetic biological TV"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
                <Submenu label="Mechanical Valve">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Prosthetic mechanical TV well seated"
                  >
                    Well seated
                  </Item>
                  <Submenu label="Dysfunction">
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Regurgitant prosthetic mechanical TV"
                    >
                      Regurgitant
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Stenotic prosthetic mechanical TV"
                    >
                      Stenotic
                    </Item>
                    <Item
                      closeOnClick={false}
                      onClick={HandleItemClick}
                      data="Vegetation prosthetic mechanical TV"
                    >
                      Vegetation
                    </Item>
                  </Submenu>
                </Submenu>
              </Submenu>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Flail TV"
              >
                Flail
              </Item>
            </Submenu>
            <Submenu label="Function">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="RVSP cannot accurately be estimated"
              >
                RVSP cannot accurately be estimated
              </Item>
              <Submenu label="Regurgitation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trivial regurgitation tricuspid valve"
                >
                  Trivial
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace regurgitation tricuspid valve"
                >
                  Trace
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild regurgitation tricuspid valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate regurgitation tricuspid valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate regurgitation tricuspid valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe regurgitation tricuspid valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe regurgitation tricuspid valve"
                >
                  Severe
                </Item>
              </Submenu>
              <Submenu label="RAP">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="RAP 3 mmHg"
                >
                  3 mmHg
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="RAP 8 mmHg"
                >
                  8 mmHg
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="RAP 15 mmHg"
                >
                  15 mmHg
                </Item>
              </Submenu>
              <Submenu label="Pulmonary HTN">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Borderline pulmonary HTN"
                >
                  Borderline
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Trace pulmonary HTN"
                >
                  Trace
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild pulmonary HTN"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate pulmonary HTN"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe pulmonary HTN"
                >
                  Severe
                </Item>
              </Submenu>
              <Submenu label="Stenosis">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild stenosis tricuspid valve"
                >
                  Mild
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Mild-Moderate stenosis tricuspid valve"
                >
                  Mild-Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate stenosis tricuspid valve"
                >
                  Moderate
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Moderate-Severe stenosis tricuspid valve"
                >
                  Moderate-Severe
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Severe stenosis tricuspid valve"
                >
                  Severe
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Tamponade">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Inspiration variability in TV inflow"
              >
                Inspiration variability in TV inflow
              </Item>
            </Submenu>
          </Submenu>
        </Menu>
      );

    case "laAndRa":
      return (
        <Menu id="laAndRa">
          <Submenu label="Biatrial enlargement">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mild biatrial enlargement"
            >
              Mild
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate biatrial enlargement"
            >
              Moderate
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Severe biatrial enlargement"
            >
              Severe
            </Item>
          </Submenu>
          <Submenu label="LA">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LA"
            >
              Normal
            </Item>
            <Submenu label="LAVI">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LAVI"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Upper limit of normal LAVI"
              >
                Upper limit of normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly increased LAVI"
              >
                Mildly increased
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately increased LAVI"
              >
                Moderately increased
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely increased LAVI"
              >
                Severely increased
              </Item>
            </Submenu>
            <Submenu label="Size">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LA size"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Upper limit of normal LA size"
              >
                Upper limit of normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly dilated left atrium"
              >
                Mildly dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately dilated left atrium"
              >
                Moderately dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely dilated left atrium"
              >
                Severely dilated
              </Item>
            </Submenu>
            <Submenu label="Masses">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Myxoma"
              >
                Myxoma
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Other masses"
              >
                Others
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LA Collapse consistent with Tamponade"
            >
              Collapse consistent with Tamponade
            </Item>
          </Submenu>
          <Submenu label="RA">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal RA"
            >
              Normal
            </Item>
            <Submenu label="Volume/Surface Area">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Upper limit of normal volume"
              >
                Upper limit of normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly dilated RA"
              >
                Mildly dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately dilated RA"
              >
                Moderately dilated
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely dilated RA"
              >
                Severely dilated
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Wired noticed on RA"
            >
              Wired noticed
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="RA collapsibility due to increased pressure from pericardial effusion"
            >
              Collapsibility due to increased pressure from pericardial effusion
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="RA Collapse consistent with Tamponade"
            >
              Collapse consistent with Tamponade
            </Item>
          </Submenu>
          <Submenu label="IAS">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Intact interatrial septum. No evidence of interatrial shunt via colour flow doppler"
            >
              Normal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mobile IAS"
            >
              Mobile
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Aneurysmal IAS"
            >
              Aneurysmal
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Negative agitated saline contrast study ruling out interatrial shunt"
            >
              Negative agitated saline contrast study ruling out interatrial
              shunt
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Agitated saline contrast study confirms interatrial shunt"
            >
              Agitated saline contrast study confirms interatrial shunt
            </Item>
            <Submenu label="PFO">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="L-R SHUNT PFO"
              >
                L-R SHUNT
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="R-L SHUNT PFO"
              >
                R-L SHUNT
              </Item>
            </Submenu>
            <Submenu label="ASD">
              <Submenu label="Secundum">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="L-R SHUNT Secundum ASD"
                >
                  L-R SHUNT
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="R-L SHUNT Secundum ASD"
                >
                  R-L SHUNT
                </Item>
              </Submenu>
              <Submenu label="Primum">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="L-R SHUNT Primum ASD"
                >
                  L-R SHUNT
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="R-L SHUNT Primum ASD"
                >
                  R-L SHUNT
                </Item>
              </Submenu>
              <Submenu label="Sinus Venosus">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="L-R SHUNT Sinus Venosus ASD"
                >
                  L-R SHUNT
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="R-L SHUNT Sinus Venosus ASD"
                >
                  R-L SHUNT
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="IAS repair noted">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="IAS repair noted no flow remains"
              >
                No flow remains
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="IAS repair noted colour doppler evidence of shunting"
              >
                Colour doppler evidence of shunting
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Lipomatous hypertrophy"
            >
              Lipomatous hypertrophy
            </Item>
          </Submenu>
        </Menu>
      );

    case "vessels":
      return (
        <Menu id="vessels">
          <Submenu label="Aorta">
            <Submenu label="Aorta Root">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal aorta"
              >
                Normal
              </Item>
              <Submenu label="Sinus of valsalva">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Sinus of valsalva mildly dilated"
                >
                  Mildly dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Sinus of valsalva moderately dilated"
                >
                  Moderately dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Sinus of valsalva severely dilated"
                >
                  Severely dilated
                </Item>
              </Submenu>
              <Submenu label="ST Junction">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="ST Junction mildly dilated"
                >
                  Mildly dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="ST Junction moderately dilated"
                >
                  Moderately dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="ST Junction severely dilated"
                >
                  Severely dilated
                </Item>
              </Submenu>
              <Submenu label="Coronary arteries">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Dilated coronary arteries noted"
                >
                  Dilated coronary arteries noted
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Ascending Aorta">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal ascending aorta"
              >
                Normal
              </Item>
              <Submenu label="Dilation">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Ascending aorta mildly dilated"
                >
                  Mildly dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Ascending aorta moderately dilated"
                >
                  Moderately dilated
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Ascending aorta severely dilated"
                >
                  Severely dilated
                </Item>
              </Submenu>
              <Submenu label="Dissection">
                <Submenu label="Type A">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Type I ascending aorta dissection"
                  >
                    Type I
                  </Item>
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Type II ascending aorta dissection"
                  >
                    Type II
                  </Item>
                </Submenu>
                <Submenu label="Type B">
                  <Item
                    closeOnClick={false}
                    onClick={HandleItemClick}
                    data="Type III ascending aorta dissection"
                  >
                    Type III
                  </Item>
                </Submenu>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Cannot rule out ascending aorta dissection"
                >
                  Cannot rule out
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Coarctation ascending aorta dissection"
                >
                  Coarctation
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Descending Aorta">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Coarctation descending aorta"
              >
                Coarctation
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Dilation descending aorta"
              >
                Dilation
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Diastolic flow reversal descending aorta"
              >
                Diastolic flow reversal
              </Item>
            </Submenu>
            <Submenu label="Abdominal Aorta">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Dilation abdominal aorta"
              >
                Dilation
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Diastolic flow reversal abdominal aorta"
              >
                Diastolic flow reversal
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="IVC">
            <Submenu label="Size">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal IVC"
              >
                Normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Upper limit of normal IVC"
              >
                Upper limit of normal
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Dilated IVC"
              >
                Dilated
              </Item>
            </Submenu>
            <Submenu label="Collapsibility">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="> 50% IVC Collapsibility"
              >
                {">"} 50%
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="< 50% IVC Collapsibility"
              >
                {"<"} 50%
              </Item>
            </Submenu>
            <Submenu label="Hepatic vein flow">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Hepatic vein systolic flow reversal"
              >
                Systolic flow reversal
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="PA">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal PA"
            >
              Normal
            </Item>
            <Submenu label="Dilated">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Mildly dilated PA"
              >
                Mid
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Moderately dilated PA"
              >
                Moderate
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Severely dilated PA"
              >
                Severe
              </Item>
            </Submenu>
            <Submenu label="PDA">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="PA PDA L-R Shunt"
              >
                L-R Shunt
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="PA PDA R-L Shunt"
              >
                R-L Shunt
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="Pulmonary Veins">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal filling pattern pulmonary veins"
            >
              Normal filling pattern
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Systolic flow reversal pulmonary veins"
            >
              Systolic flow reversal
            </Item>
          </Submenu>
          <Submenu label="Miscellaneous">
            <Submenu label="Pericardial effusion">
              <Submenu label="Size">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Small pericardial effusion"
                >
                  Small
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Medium pericardial effusion"
                >
                  Medium
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Large pericardial effusion"
                >
                  Large
                </Item>
              </Submenu>
              <Submenu label="Localized">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Anteriorly localized pericardial effusion"
                >
                  Anteriorly
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Posteriorly localized pericardial effusion"
                >
                  Posteriorly
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="Laterally localized pericardial effusion"
                >
                  Laterally
                </Item>
              </Submenu>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Circumferential pericardial effusion"
              >
                Circumferential
              </Item>
              <Submenu label="Collapsibility">
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LV collapsibility pericardial effusion"
                >
                  LV
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="LA collapsibility pericardial effusion"
                >
                  LA
                </Item>
                <Item
                  closeOnClick={false}
                  onClick={HandleItemClick}
                  data="RA collapsibility pericardial effusion"
                >
                  RA
                </Item>
              </Submenu>
            </Submenu>
            <Submenu label="Tamponade">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Tamponade cannot be ruled out"
              >
                Cannot be ruled out
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Echo signs for tamponade"
              >
                Echo signs
              </Item>
            </Submenu>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pleural effusion"
            >
              Pleural effusion
            </Item>
            <Submenu label="Extra cardiac">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Extra cardiac hiatal hernia"
              >
                Hiatal hernia
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Extra cardiac hepatic cysts"
              >
                Hepatic cysts
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="Coronary sinus">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Dilated coronary sinus"
            >
              Dilated
            </Item>
          </Submenu>
          <Submenu label="Cardiomyopathy">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hypertrophic cardiomyopathy"
            >
              Hypertrophic
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Dilated cardiomyopathy"
            >
              Dilated
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Arrhythmogenic cardiomyopathy"
            >
              Arrhythmogenic
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Restrictive cardiomyopathy"
            >
              Restrictive
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Left ventricular noncompaction"
            >
              Left ventricular noncompaction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Takotsubo cardiomyopathy or broken heart syndrome"
            >
              Takotsubo or broken heart syndrome
            </Item>
          </Submenu>
        </Menu>
      );

    case "testConclusion":
      return (
        <Menu id="testConclusion">
          <Item closeOnClick={false} onClick={HandleItemClick} data="Normal">
            Normal
          </Item>
          <Submenu label="Findings">
            <Submenu label="Normal">
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Normal LV systolic function, no significant valvular abnormalities"
              >
                No significant valvular abnormalities
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Compared to prior study, there have not been any significant changes"
              >
                Compared to prior study, there have not been any significant
                changes
              </Item>
              <Item
                closeOnClick={false}
                onClick={HandleItemClick}
                data="Endocarditis prophylaxis is NOT recommended"
              >
                Endocarditis prophylaxis is NOT recommended
              </Item>
            </Submenu>
          </Submenu>
          <Submenu label="Recommendations">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal LV systolic function. No significant valvular abnormalities"
            >
              Normal LV systolic function. No significant valvular abnormalities
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Compared to prior study, there have not been any major changes"
            >
              Compared to prior study, there have not been any major changes
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Compared to prior study, there have been the following changes:"
            >
              Compared to prior study, there have been the following changes:
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Endocarditis prophylaxis is indicated in this setting"
            >
              Endocarditis prophylaxis is indicated in this setting
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Endocarditis prophylaxis is NOT indicated"
            >
              Endocarditis prophylaxis is NOT indicated
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is no obvious source of cardiac emboli"
            >
              There is no obvious source of cardiac emboli
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider repeat Echocardiogram in 6, 12, months"
            >
              Consider repeat Echocardiogram in 6, 12, months
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is no evidence of valvular vegetations or endocarditis"
            >
              There is no evidence of valvular vegetations or endocarditis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings in keeping with prior myocardial infarction"
            >
              Findings in keeping with prior myocardial infarction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings in keeping with hypertrophic cardiomyopathy"
            >
              Findings in keeping with hypertrophic cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="There is increased speckled appearance of myocardium. Consider infiltrative disorders such as cardiac amyloidosis"
            >
              There is increased speckled appearance of myocardium...
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Findings suggestive of dilated cardiomyopathy"
            >
              Findings suggestive of dilated cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider repeat Echocardiogram with IV contrast"
            >
              Consider repeat Echocardiogram with IV contrast
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="14. Clinical correlation required"
            >
              Clinical correlation required
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hepatic cysts. Consider correlate with abdominal ultrasound"
            >
              Hepatic cysts. Consider correlate with abdominal ultrasound
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Consider transesophageal echocardiogram for further evaluation if clinically indicated"
            >
              Consider transesophageal echocardiogram for further evaluation if
              clinically indicated
            </Item>
          </Submenu>
        </Menu>
      );

    case "pathology":
      return (
        <Menu id="pathology">
          <Submenu label="Ventricular Diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Segmental left ventricular dysfunction and LVEF calculation"
            >
              Segmental left ventricular dysfunction and LVEF calculation
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Global left ventricular dysfunction"
            >
              Global left ventricular dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Infiltrative diseases"
            >
              Infiltrative diseases
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Hypertrophic cardiomyopathy"
            >
              Hypertrophic cardiomyopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Global and segmental right ventricular dysfunction"
            >
              Global and segmental right ventricular dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Positive stress echocardiography (exercise and dobutamine) for ischemia"
            >
              Positive stress echocardiography for ischemia
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Distinct patterns of regional LV strain"
            >
              Distinct patterns of regional LV strain
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Left ventricular diastolic dysfunction"
            >
              Left ventricular diastolic dysfunction
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal septal motion/cardiac dys-synchrony"
            >
              Abnormal septal motion/cardiac dys-synchrony
            </Item>
          </Submenu>
          <Submenu label="Native valvular heart diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with normal EF and normal stroke volume"
            >
              Moderate or worse AS with normal EF and normal stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with normal EF and low stroke volume"
            >
              Moderate or worse AS with normal EF and low stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with low EF and low stroke volume"
            >
              Moderate or worse AS with low EF and low stroke volume
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AS with discrepant Doppler measures"
            >
              Moderate or worse AS with discrepant Doppler measures
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Positive dobutamine stress echo for severe AS"
            >
              Positive dobutamine stress echo for severe AS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse AI"
            >
              Moderate or worse AI
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="All grades of mitral stenosis"
            >
              All grades of mitral stenosis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="MS score appropriately applied to rheumatic stenosis"
            >
              MS score appropriately applied to rheumatic stenosis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Primary MR due to MV prolapse or other primary MV abnormality"
            >
              Primary MR due to MV prolapse or other primary MV abnormality
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Functional due to ventricular dysfunction, functional atrial MR, ischemic MR with tethered posterior leaflet"
            >
              Functional due to ventricular dysfunction, functional atrial MR,
              ischemic MR
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse PS"
            >
              Moderate or worse PS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse PR"
            >
              Moderate or worse PR
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse TS"
            >
              Moderate or worse TS
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Moderate or worse TR (primary or secondary)"
            >
              Moderate or worse TR (primary or secondary)
            </Item>
          </Submenu>
          <Submenu label="Pericardial diseases">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial effusion with associated pertinent
              hemodynamics"
            >
              Pericardial effusion with associated pertinent hemodynamics
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pericardial tamponade (pre and post pericardiocentesis assessment)"
            >
              Pericardial tamponade (pre and post pericardiocentesis assessment)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Constrictive pericarditis"
            >
              Constrictive pericarditis
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Effusive constrictive pericarditis"
            >
              Effusive constrictive pericarditis
            </Item>
          </Submenu>
          <Submenu label="Percutaneous or surgically implanted cardiac devices">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Permanent pacemaker/defibrillator wires (in the RA and RV)"
            >
              Permanent pacemaker/defibrillator wires (in the RA and RV)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Venous catheters (in the IVC and RA)"
            >
              Venous catheters (in the IVC and RA)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="PFO/ASD closure devices in the interatrial septum"
            >
              PFO/ASD closure devices in the interatrial septum
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Left atrial appendage closure device"
            >
              Left atrial appendage closure device
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bioprosthesis Aortic – Normal, Stenotic, Regurgitant"
            >
              Bioprosthesis Aortic – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bioprosthesis Mitral – Normal, Stenotic, Regurgitant"
            >
              Bioprosthesis Mitral – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mechanical Aortic – Normal, Stenotic, Regurgitant"
            >
              Mechanical Aortic – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mechanical Mitral – Normal, Stenotic, Regurgitant"
            >
              Mechanical Mitral – Normal, Stenotic, Regurgitant
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Mitral valve post repair (including annuloplasty ring, MitraClip)"
            >
              Mitral valve post repair (including annuloplasty ring, MitraClip)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abnormal MV repair hemodynamics (increased gradients)"
            >
              Abnormal MV repair hemodynamics (increased gradients)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Stenotic prosthetic valves"
            >
              Stenotic prosthetic valves
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Regurgitant prosthetic valves – valvular"
            >
              Regurgitant prosthetic valves – valvular
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Regurgitant prosthetic valves - paravalvular"
            >
              Regurgitant prosthetic valves - paravalvular
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Infective endocarditis and its complications"
            >
              Infective endocarditis and its complications
            </Item>
          </Submenu>
          <Submenu label="Intracardiac masses, pseudo-masses, and cardiac sources of embolism">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Cardiac myxoma"
            >
              Cardiac myxoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Cardiac lipoma"
            >
              Cardiac lipoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Papillary fibroelastoma"
            >
              Papillary fibroelastoma
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Lambl’s excrescence"
            >
              Lambl’s excrescence
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="LV apical thrombus"
            >
              LV apical thrombus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Intracardiac shunts due to PFO/secundum ASD "
            >
              Intracardiac shunts due to PFO/secundum ASD
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Normal variants/pseudomasses"
            >
              Normal variants/pseudomasses
            </Item>
          </Submenu>
          <Submenu label="Congenital heart diseases (operated and non-operated)">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Atrial septal defect"
            >
              Atrial septal defect
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Atrio-ventricular septal defect repair"
            >
              Atrio-ventricular septal defect repair
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Dilated coronary sinus"
            >
              Dilated coronary sinus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Persistent left-sided SVC"
            >
              Persistent left-sided SVC
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Ventricular septal defect"
            >
              Ventricular septal defect
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Partial anomalous pulmonary venous connection"
            >
              Partial anomalous pulmonary venous connection
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Patent ductus arteriosus"
            >
              Patent ductus arteriosus
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Ebstein’s anomaly"
            >
              Ebstein’s anomaly
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Tetralogy of Fallot"
            >
              Tetralogy of Fallot
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Transposition of the great arteries"
            >
              Transposition of the great arteries
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Pulmonary arterial hypertension"
            >
              Pulmonary arterial hypertension
            </Item>
          </Submenu>
          <Submenu label="Diseases of the aorta">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Thoracic aortic disease"
            >
              Thoracic aortic disease
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Abdominal aortic disease (aneurysm, atheromatous changes)"
            >
              Abdominal aortic disease (aneurysm, atheromatous changes)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Bicuspid aortic valve (with and without aortopathy)"
            >
              Bicuspid aortic valve (with and without aortopathy)
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Marfan syndrome with aortopathy"
            >
              Marfan syndrome with aortopathy
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Coarctation of aorta"
            >
              Coarctation of aorta
            </Item>
          </Submenu>
          <Submenu label="Normal cases and artefacts">
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Artifacts"
            >
              Artifacts
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to poorly aligned Doppler"
            >
              Incorrect continuous wave measurements due to poorly aligned
              Doppler
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to overgain"
            >
              Incorrect continuous wave measurements due to overgain
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect continuous wave measurements due to measuring confounding flow"
            >
              Incorrect continuous wave measurements due to measuring
              confounding flow
            </Item>
            <Item
              closeOnClick={false}
              onClick={HandleItemClick}
              data="Incorrect colour flow assessment"
            >
              Incorrect colour flow assessment
            </Item>
          </Submenu>
        </Menu>
      );

    default:
      console.log(`Sorry, we are out of ${menuid.menuid}.`);
      return null;
  }
}
