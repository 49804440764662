import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Hub } from "aws-amplify/utils";

import {
  Flex,
  Icon,
  Heading,
  Box,
  Grid,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { MdFavoriteBorder as HeartIcon } from "react-icons/md";
import { FaMoon, FaSun } from "react-icons/fa";

import { useUser } from "../UserContext";

import AccountButton from "./AccountButton";

const styles = {
  logo: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  logoContainer: {
    background: "#fff",
    borderRadius: "50%",
    padding: ".25rem",
    margin: ".25rem",
    height: "95px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 400,
    color: "white",
    alignItems: "center",
    marginLeft: ".5rem",
    textTransform: "capitalize",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
};

const Topbar = ({ signOut }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const user = useUser();
  const userattr = user?.attributes;

  return (
    <Box as="header" bg="#3f51b5" color="white" p="0 2vw">
      <Flex align="center" justify="space-evenly" minHeight="10vh">
        <div>
          <Flex to="/" as={RouterLink} fontSize="1rem">
            <Icon as={HeartIcon} w="12" h="12" />
            <Heading
              display={{ base: "none", md: "flex" }}
              as="h1"
              style={styles.mainTitle}
            >
              Echo Augmented Reporting
            </Heading>
          </Flex>
        </div>
        <div style={styles.logo}>
          <Flex
            display={{ base: "none", md: "flex" }}
            to="/"
            as={RouterLink}
            fontSize="1rem"
          >
            {user && (
              <div style={styles.logoContainer}>
                <img
                  src={
                    userattr?.["custom:clinicLogo"]
                      ? userattr["custom:clinicLogo"]
                      : "https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ARLogoIcon.png"
                  }
                  alt="logo"
                  width="90"
                />
              </div>
            )}
          </Flex>
        </div>
        {/*        <IconButton
          icon={colorMode === 'light' ? <FaSun /> : <FaMoon />}
          isRound="true"
          size="lg"
          alignSelf="flex-end"
          onClick={toggleColorMode}
        />
*/}
        <div>
          {user && (
            <Grid gap="1rem" gridTemplateColumns="auto 1fr">
              <Flex display={{ base: "none", md: "flex" }} align="center">
                {userattr?.email}
              </Flex>
              <AccountButton signOut={signOut} />
            </Grid>
          )}
        </div>
      </Flex>
    </Box>
  );
};

export default Topbar;
