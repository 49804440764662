import { useQuery } from "react-query";

import {
  getAllSeries,
  getAllClinics,
  getAllSeriesForAudit,
  getAllReportTemplates,
  getAllReferringPhysicians,
  getAllNormalValueMeasurements,
} from "../services/form-api-service";

const getAllFunctionsByCollection = {
  series: getAllSeries,
  seriesForAudit: getAllSeriesForAudit,
  clinics: getAllClinics,
  templates: getAllReportTemplates,
  referringPhysicians: getAllReferringPhysicians,
  normalValues: getAllNormalValueMeasurements,
};

export function useGetAllQuery(collection) {
  const { data, isLoading } = useQuery(
    collection,
    getAllFunctionsByCollection[collection]
  );
  return { data, isLoading };
}
