import { useState, useEffect } from "react";

// if arg 1 contains all items in arg 2, return true.
export function arrayContainsArray(superset, subset) {
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
}

// if item is in array, removes it. Otherwise adds it.
export function removeOrAddItemFromArray(item, array) {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  } else {
    return [...new Set([...array, item])];
  }
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// incoming dateString is in the format YYYYMMDD, example: 20191231
// returned date format should be mmm-dd-yyyy, example: Dec-31-2019
export function customDateFormat(dateStr) {
  if (!dateStr) {
    return "";
  }

  // Convert the month string to a number and get the equivalent month from months array
  const month = months[parseInt(dateStr.slice(4, 6)) - 1];
  // Construct date in the format mmm-dd-yyyy
  const formattedDate = `${month}-${dateStr.slice(6)}-${dateStr.slice(0, 4)}`;
  return formattedDate;
}

export function sortByDate(arr) {
  return arr.sort(function (a, b) {
    return (
      new Date(customDateFormat(b.SeriesDate)) -
      new Date(customDateFormat(a.SeriesDate))
    );
  });
}

export function sortAndFilterByDate(arr, SeriesDate) {
  const sortedArr = sortByDate(arr);

  return sortedArr.filter(
    (study) =>
      new Date(customDateFormat(study.SeriesDate)) <
      new Date(customDateFormat(SeriesDate))
  );
}

// Converts the input into a string and pads the start of the input with a 0 if necessary
function convertToStringWithPadding(number) {
  return number.toString().padStart(2, "0");
}

export function getDateFromDateTimeString(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const date = convertToStringWithPadding(dateTime.getDate());
  // Construct date in the format mmm-dd-yyyy
  const formattedFullDate = `${
    months[dateTime.getMonth()]
  }-${date}-${dateTime.getFullYear()}`;

  return formattedFullDate;
}

export function getTimeFromDateTimeString(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const hour = dateTime.getHours();
  // Convert from 24 hours time to 12 hours time and convert to string with 0 padding
  const formattedHour = convertToStringWithPadding(
    hour > 12 ? hour - 12 : hour
  );
  // Construct the time in the format HH:MM am/pm
  const formattedTime =
    formattedHour +
    ":" +
    convertToStringWithPadding(dateTime.getMinutes()) +
    ` ${hour >= 12 ? "pm" : "am"}`;

  return formattedTime;
}

export function hasMatchingWord(str1, str2) {
  const words1 = str1?.toLowerCase().split(" ");
  const words2 = str2?.toLowerCase().split(" ");

  return words1?.some((word) => words2?.includes(word));
}

export function customDateAndTimeFormat(dateTimeString) {
  return `${getDateFromDateTimeString(
    dateTimeString
  )}, ${getTimeFromDateTimeString(dateTimeString)}`;
}

// Source: https://www.w3resource.com/javascript-exercises/javascript-date-exercise-18.php
export function calculateAge(dobString) {
  if (!dobString) {
    return "";
  }

  const dob = new Date(customDateFormat(dobString));
  const differenceInMilliseconds = Date.now() - dob.getTime();
  const ageDate = new Date(differenceInMilliseconds);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

// Source: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
export const stripHTML = (originalString) =>
  originalString ? originalString.replace(/(<([^>]+)>)/gi, "") : "";

// Source: https://stackoverflow.com/questions/15392807/replacing-carets-in-javascript
export const spaceForCaret = (originalString) =>
  (originalString ? originalString.replace(/\^/g, " ") : "").trim();

export const lastCommaFirst = (originalString) =>
  originalString.replace(" ", ", ");

// Source: https://usehooks.com/useWindowSize/
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
