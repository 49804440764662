import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Grid, Avatar, Flex, Box } from "@chakra-ui/react";

import { useUser } from "../UserContext";

import Highlight from "./Highlight";
import { SecondaryButton } from "./Buttons";

const Profile = () => {
  const history = useHistory();
  const user = useUser();
  const userattr = user.attributes;

  return (
    <Grid>
      <SecondaryButton
        onClick={history.goBack}
        style={{ margin: "2rem 0 2rem 1rem" }}
      >
        GO BACK
      </SecondaryButton>
      <Grid gap="1vw" templateColumns="auto 3fr" p="1rem">
        <Avatar name={userattr.email} alt={userattr.email} />
        <Flex direction="column">
          <p className="lead text-muted">{userattr.email}</p>
        </Flex>
      </Grid>
      <Box display={{ base: "270px", md: "flex" }} p="1rem">
        <Highlight>{JSON.stringify(userattr, null, 2)}</Highlight>
      </Box>
    </Grid>
  );
};

export default Profile;
