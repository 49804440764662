import { useEffect, useState } from "react";
import {
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  Button,
  Thead,
  Th,
  Spinner,
  Flex,
} from "@chakra-ui/react";

import { getStatusOfFaxesFromLastOneMonth } from "../services/form-api-service";

import "./FaxStatusPage.css";

export default function FaxStatusPage() {
  const [faxes, setFaxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const faxesData = await getStatusOfFaxesFromLastOneMonth();
        setFaxes(faxesData);
      } catch (error) {
        console.error("Error fetching faxes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleClickViewPDF = (SeriesInstanceUID) => {
    const baseURL = window.location.origin;

    // if (patientData.studyStatus === "Signed") {
    //   window.open(
    //     `${baseURL}/report/${patientData.SeriesInstanceUID}`,
    //     "_blank",
    //     "toolbar=0,location=0,menubar=0"
    //   );
    // } else {
    // setAccessType("view");
    //    submitForm();
    setTimeout(() => {
      // setAccessType("");
      window.open(
        `${baseURL}/report/${SeriesInstanceUID}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      );
    }, 2500);
    // }
  };

  function formatPhoneNumber(phoneNumber) {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "").replace(/^1/, "");

    // Format the phone number in the desired format
    const formattedNumber = digitsOnly.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  }

  function formatDate(date) {
    // format date as MMM DD, YYYY
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return formattedDate;
  }

  if (isLoading) {
    return (
      <Flex
        direction="column"
        align="center"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10rem 0 15rem",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  return (
    <div className="fax-status-page">
      <Heading as="h1" fontSize="48px" marginBottom="24px">
        Fax Status Page
      </Heading>

      <Heading as="h2" fontSize="32px">
        Failed Faxes
      </Heading>

      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>To</Th>
            <Th>Patient Name</Th>
            {/* <Th>Status</Th> */}
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {faxes.map((fax) => (
            <Tr key={fax.id}>
              <Td>{formatDate(fax.created_at)}</Td>
              <Td>{formatPhoneNumber(fax.to)}</Td>
              <Td>{fax.patientName}</Td>
              {/* <Td>{fax.status}</Td> */}
              <Td>
                <Button
                  colorScheme="blue"
                  onClick={() => handleClickViewPDF(fax.SeriesInstanceUID)}
                >
                  View PDF to resend fax
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}
