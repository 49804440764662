import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { setupFilterConfig } from "../filterConfig";
import { post, get, generateClient } from "aws-amplify/api";

const client = generateClient();

export const getCurrentAuthenticatedUser = async () => {
  // const user = await getCurrentUser({ bypassCache: true }) // bypassCache should be kept true during dev for easier testing
  const user = await fetchUserAttributes({})
    .then((user) => user)
    .catch((err) => {
      console.log("err: ", err);
    });
  setupFilterConfig(user); // pass the user to set up the filter menu

  return user;
};

export const getCurrentSession = async () => {
  const session = await fetchAuthSession()
    .then((data) => data)
    .catch((err) => console.log(err));

  return session;
};

export const getCookieWithSuffix = (suffix) => {
  const cookies = document.cookie.split("; "); // Split all cookies into an array
  for (let cookie of cookies) {
    if (cookie.includes(suffix)) {
      return cookie.split("=")[1]; // Extract and return the value part
    }
  }
  return null; // Return null if no matching cookie is found
};

export const listClinicUsers = async (clinicGroup) => {
  let apiName = "AdminQueries";
  let path = "/listUsers";
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await fetchAuthSession()).tokens.accessToken}`,
    },
  };
  // https://docs.amplify.aws/gen1/javascript/build-a-backend/restapi/fetch-data/#accessing-response-payload
  try {
    const { body } = await get({ apiName, path, options }).response;
    const bodyJSON = await body.json();

    const allUsers = bodyJSON.Users || [];

    const usersWithGroups = await Promise.all(
      allUsers.map(async (user) => {
        const groups = await listUserGroups(user.Username);
        return { user, groups };
      })
    );
    // Filter users who belong to the specified clinic group
    const clinicUsers = usersWithGroups
      .filter(({ groups }) =>
        groups.some((group) => group.GroupName === clinicGroup)
      )
      .map(({ user }) => user);
    return clinicUsers;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

/// Extracting required fields from each user and sorting the users
export const extractSortedUserData = (clinicUsers) => {
  // Map through users to extract attributes
  const extractedUsers = clinicUsers.map((user) => {
    const attributes = user.Attributes.reduce((acc, attr) => {
      acc[attr.Name] = attr.Value;
      return acc;
    }, {});

    return {
      id:
        user.UserStatus === "EXTERNAL_PROVIDER"
          ? user.Username
          : attributes["sub"],
      email: attributes["email"],
      role: attributes["custom:role"],
      adminConfirmed: attributes["custom:adminConfirmed"],
      clinicName: attributes["custom:clinicName"],
    };
  });

  // Sort users by adminConfirmed and then by email
  const sortedUsers = extractedUsers.sort((a, b) => {
    // First, compare adminConfirmed values: "no" should come before "yes"
    const adminA = a.adminConfirmed === "yes" ? 1 : 0;
    const adminB = b.adminConfirmed === "yes" ? 1 : 0;
    if (adminA !== adminB) {
      return adminA - adminB;
    }
    // If adminConfirmed values are the same, sort by email alphabetically
    return a.email.localeCompare(b.email);
  });

  return sortedUsers;
};

export const listUserGroups = async (username) => {
  const apiName = "AdminQueries";
  const path = `/listGroupsForUser?username=${username}`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await fetchAuthSession()).tokens.accessToken}`,
    },
  };

  try {
    const { body } = await get({ apiName, path, options }).response;
    const bodyJSON = await body.json();
    return bodyJSON.Groups;
  } catch (error) {
    console.error(`Error fetching groups for user ${username}:`, error);
    return [];
  }
};

export const updateUserAttributes = async (username, attributes) => {
  let apiName = "AdminQueries";
  let path = "/updateUserAttributes";
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await fetchAuthSession()).tokens.accessToken}`,
    },
    body: {
      username,
      attributes,
    },
  };
  try {
    await post({ apiName, path, options });
  } catch (error) {
    console.error("Error updating user attributes:", error);
    throw error;
  }
};
