import React from "react";
import {
  Authenticator,
  withAuthenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  useAuthenticator,
  Button,
  CheckboxField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserProvider } from "./UserContext";

const queryClient = new QueryClient();

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="AR logo"
          src="https://futurepacspubfiles.s3.ca-central-1.amazonaws.com/ARLogoIcon.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          <SelectField
            name="custom:role"
            label="Role"
            placeholder="Please select a role"
          >
            <option value="cardiologist">Cardiologist</option>
            <option value="sonographer">Sonographer</option>
            <option value="learner">Learner</option>
            <option value="educator">Educator</option>
            <option value="patient">Patient</option>
          </SelectField>
          <TextField
            name="custom:clinicName"
            label="Organization"
            placeholder="Please enter the organization name"
          />
          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            //            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="I agree with the Terms & Conditions"
          />
        </>
      );
    },
  },

  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Email support@futurepacs.com For Confirmation
        </Heading>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Reset Password:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Reset Password:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
  LoadingIndicator: () => <div>Loading...</div>,
};

const services = {
  async validateCustomSignUp(formData) {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: "You must agree to the Terms & Conditions",
      };
    }
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
  },
  signUp: {
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
    },
    confirm_password: {
      label: "Confirm Password:",
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignUp: {
    confirmation_code: {
      placeholder:
        "support@futurepacs.com will email you confirmation of your registration.",
      isRequired: false,
    },
  },
};

const AppWithAuth = withAuthenticator(
  ({ user, signOut }) => (
    <ChakraProvider>
      <ColorModeScript initialColorMode="light" />
      <UserProvider key={user?.username} user={user}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App signOut={signOut} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>
      </UserProvider>
    </ChakraProvider>
  ),
  {
    components,
    services,
    formFields,
    loginMechanisms: ["email"],
    variation: "default",
    signUpAttributes: ["custom:role", "custom:clinicName"],
  }
);

export default AppWithAuth;
