import { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { list, getUrl } from "aws-amplify/storage";

export default function S3AmplifyFileList(props) {
  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    async function fetchFileUrls() {
      try {
        // Step 1: List all files
        const { items } = await list({
          path: `public/${props.SeriesInstanceUID}/`,
          options: {},
        }); // '' lists all files in the root
        // Step 2: Generate URLs using path and getUrl
        const urls = await Promise.all(
          items.map(async (file) => {
            const fileIdentifier = file?.path || file?.key; // Use `path` if available, otherwise `key`
            if (!fileIdentifier) {
              console.warn("File object does not contain path or key:", file);
              return null; // Skip invalid file objects
            }
            try {
              const url = await getUrl({ path: fileIdentifier }); // Generate URL
              return { identifier: fileIdentifier, url };
            } catch (error) {
              console.error(
                "Error generating URL for file:",
                fileIdentifier,
                error
              );
              return null; // Skip files that fail to generate a URL
            }
          })
        );
        /*        console.log("urls", urls);
        console.log(
          "href",
          urls[0]?.url.url.href,
          "identifier",
          urls[0]?.identifier
        );*/

        setFileUrls(urls.filter((file) => file !== null));
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    }

    fetchFileUrls();
  }, [props.SeriesInstanceUID, props.numFilesUploaded]);

  // handles unique key with hyphen
  const regex = /(?<=-%20)(.*)(?=\?X-Amz)/i;

  //   const regex = /(?<=public\/.*\/)(.*)(?=\?X-Amz)/i;

  //  const regex = /(?<=amazonaws.com\/.*\/.*\/)(.*)(?=\?)/;

  //  const regex = /(?<=protected\/.*\/)(.*)(?=\/)/i;

  return (
    <TableContainer>
      <Table size="sm" variant="striped">
        <Thead>
          <Tr>
            <Th>Uploaded Report Files (click to view)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fileUrls.length > 0 ? (
            fileUrls?.map((fileUrl) => {
              const decodedFileName = regex.exec(fileUrl?.url.url.href) || [
                "Unknown File",
              ];
              return (
                <Tr key={fileUrl?.identifier}>
                  <Td>
                    <a
                      href={fileUrl?.url.url.href}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {decodeURI(decodedFileName[0])}
                    </a>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td>No files uploaded</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
