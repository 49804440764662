import React, { useEffect, useState } from "react";
import {
  listClinicUsers,
  extractSortedUserData,
  updateUserAttributes,
} from "../services/auth.service"; // Helper functions for Cognito interactions
import { useUser } from "../UserContext";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Box,
  useToast,
} from "@chakra-ui/react";

const UserMgmt = () => {
  const [users, setUsers] = useState([]);
  const [changes, setChanges] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [componentKey, setComponentKey] = useState(0);
  const toast = useToast();
  const admin = useUser();
  const adminattr = admin?.attributes || {};
  const adminsession = admin?.session || {};
  const adminGroups =
    adminsession.tokens.accessToken.payload["cognito:groups"] || [];
  const refreshComponent = () => {
    setComponentKey((prev) => prev + 1);
  };

  useEffect(() => {
    setIsAdmin(adminGroups.includes("Admins"));
  }, [adminGroups]);

  const clinicGroup = adminGroups.filter((group) =>
    group.includes("Clinic")
  )[0];

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const clinicUsers = await listClinicUsers(clinicGroup);
      const extractedSortedUsers = await extractSortedUserData(clinicUsers);
      setUsers(extractedSortedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast({
        title: "Error",
        description: "Failed to fetch users.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [clinicGroup, toast]);

  const handleInputChange = (userId, field, value) => {
    setChanges((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        [field]: value,
      },
    }));
  };

  const handleSaveChanges = async () => {
    try {
      for (const user of users) {
        const updatedFields = changes[user.id] || {};
        const attributes = [];

        if (updatedFields.role !== undefined) {
          attributes.push({ Name: "custom:role", Value: updatedFields.role });
        }
        if (updatedFields.adminConfirmed !== undefined) {
          attributes.push({
            Name: "custom:adminConfirmed",
            Value: updatedFields.adminConfirmed,
          });
        }
        if (attributes.length > 0) {
          await updateUserAttributes(user.id, attributes);
        }
      }
      setChanges({});
      await fetchUsers();
      refreshComponent();
      setComponentKey((prev) => prev + 1);
      toast({
        title: "Success",
        description: "Users updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating users:", error);
      toast({
        title: "Error",
        description: "Failed to update users.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const roleOptions = [
    "educator",
    "learner",
    "sonographer",
    "cardiologist",
    "patient",
  ];

  return (
    <Box key={componentKey} p={4}>
      {loading ? (
        <p>Loading users...</p>
      ) : (
        <>
          {Object.keys(changes).length > 0 && (
            <Box mt={4}>
              <Button
                colorScheme="blue"
                onClick={() => {
                  const confirmation = window.confirm(
                    "Are you sure you want to save these changes?"
                  );
                  if (confirmation) {
                    handleSaveChanges();
                  }
                }}
              >
                Save Changes
              </Button>
            </Box>
          )}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Clinic Name</Th>
                <Th>Role</Th>
                <Th>Admin Confirmed</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.email}</Td>
                  <Td>{user.clinicName}</Td>
                  <Td>
                    {isAdmin ? (
                      <select
                        value={changes[user.id]?.role || user.role || ""}
                        onChange={(e) =>
                          handleInputChange(user.id, "role", e.target.value)
                        }
                      >
                        {roleOptions.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    ) : (
                      user.role
                    )}
                  </Td>
                  <Td>
                    <RadioGroup
                      value={
                        changes[user.id]?.adminConfirmed ??
                        (user.adminConfirmed ? "yes" : "no")
                      }
                      onChange={(value) =>
                        handleInputChange(user.id, "adminConfirmed", value)
                      }
                    >
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="">No</Radio>
                      </Stack>
                    </RadioGroup>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default UserMgmt;
