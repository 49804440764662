import React from "react";

import { connect } from "react-redux";

import { Box, Grid, Heading } from "@chakra-ui/layout";

import StatusReport from "./StatusReport";
import {
  calculateAge,
  customDateAndTimeFormat,
  customDateFormat,
} from "../utils/util";
import { currentPatientSelector } from "../selectors";

import "./PatientPage.css";
import { capitalize } from "lodash";

const CustomHeading3 = ({ children }) => {
  return (
    <Heading as="h3" size="md" fontWeight="500" borderBottom="1px solid black">
      {children}
    </Heading>
  );
};

const PatientAndTestInfo = ({ patient }) => {
  const genderObject = { M: "Male", F: "Female" };

  return (
    <Grid
      templateColumns="auto 20vw auto"
      autoColumns="max-content"
      justifyItems="start"
      alignItems="start"
      columnGap="1vw"
      mb="2rem"
    >
      <div>
        <CustomHeading3>PATIENT DEMOGRAPHICS</CustomHeading3>
        <Grid templateColumns="auto 1fr" columnGap="1rem">
          <p>
            <b>Name:</b> {patient.PatientName}
          </p>
          <p>
            <b>Gender:</b> {genderObject[patient.PatientSex]}
          </p>
          <p>
            <b>Age:</b> {calculateAge(patient.PatientBirthDate)}
          </p>
          <p>
            <b>BPs:</b> {patient.BPs} {patient.BPsUOM}
          </p>
          <p>
            <b>BSA:</b> {patient.BSA} {patient.BSAUOM}
          </p>
          <p>
            <b>BPd:</b> {patient.BPd} {patient.BPdUOM}
          </p>
          <p>
            <b>Height:</b> {patient.PatSize} {patient.PatSizeUOM}
          </p>
          <p>
            <b>Weight:</b> {patient.PatWeight} {patient.PatWeightUOM}
          </p>
        </Grid>
      </div>
      <div>
        <CustomHeading3>TEST INFORMATION</CustomHeading3>
        <p>
          <b>EXAM DATE:</b>{" "}
          {customDateFormat(
            !patient.SeriesDate ? patient.StudyDate : patient.SeriesDate
          )}
        </p>
        {/* <p>
          <b>REFERRING PHYSICIAN:</b> {patient.ReferringPhysicianName}
        </p> */}
        <p>
          <b>READING PHYSICIAN:</b> {patient.PhysiciansOfRecord}
        </p>
        <p>
          <b>SONOGRAPHER:</b> {patient.OperatorsName}
        </p>
      </div>
      <div>
        <CustomHeading3>STATUS REPORT</CustomHeading3>
        <p>
          <span>
            <b>STUDY STATUS:</b>
          </span>{" "}
          {patient.studyStatus}
          {patient.studyStatus === "Signed" && (
            <span style={{ color: "#e60000" }}>
              {/* &nbsp;&nbsp; (Form cannot be edited) */}
            </span>
          )}
        </p>
        <p>
          {/* patient.lastViewed (the patient prop is passed from global redux state) 
            is used instead of patientData.lastViewed  to get the date & time
            when the form was opened previously.
            patientData.lastViewed is updated when the form is opened. */}
          <span>
            <b>LAST VIEWED:</b>
          </span>{" "}
          {patient.lastViewedByUser &&
            customDateAndTimeFormat(patient.lastViewedByUser.time)}
          {patient.lastViewedByUser &&
            ` by ${patient.lastViewedByUser.email} [${capitalize(
              patient.lastViewedByUser.role
            )}]`}
        </p>
        <p>
          <span>
            <b>LAST MODIFIED:</b>
          </span>{" "}
          {patient.lastModifiedByUser &&
            customDateAndTimeFormat(patient.lastModifiedByUser.time)}
          {patient.lastModifiedByUser &&
            ` by ${patient.lastModifiedByUser.email} [${capitalize(
              patient.lastModifiedByUser.role
            )}]`}
        </p>
      </div>
    </Grid>
  );
};

const PatientPage = ({ patient }) => {
  return (
    <Box
      p="2vw"
      m="0 auto"
      fontSize="1rem"
      lineHeight="25px"
      fontFamily='-apple-system, BlinkMacSystemFont, "Roboto", sans-serif'
    >
      <StatusReport patient={patient} />
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => ({
  patient: currentPatientSelector(state, ownProps),
});

export default connect(mapStateToProps)(PatientPage);

//            <hr />
//      <PatientAndTestInfo patient={patient} />
