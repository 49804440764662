import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers";

// import Reactotron from "./ReactotronConfig";

import { persistCurrentState, supportsPersistence } from "./utils/persistence";
import throttle from "lodash/throttle";

const store = configureStore({
  reducer: appReducer,
  // Add devtools explicitly if you want customization (optional)
  devTools: process.env.NODE_ENV !== "production",
  // Middleware is included by default; you can customize it here if needed
});

// persist parts of the store whenever it changes.
if (supportsPersistence()) {
  store.subscribe(
    throttle(() => {
      persistCurrentState(store.getState(), ["bar", "settings", "favourites"]);
    })
  );
}

export default store;
